.success{
    .success-wr{
        width: calc(7.2/12*100%);
        margin: 0 auto;
        @include max(991px) {
            width: calc(9/12*100%);
        }
        @include max(700px){
            width: 100%;
        }
        .tt-sect{
            color: $color-pri;
            text-align: center;
            margin-bottom: 4rem;
            @include max(1200px) {
                margin-bottom: 2rem;
            }
        }
        .noti{
            display: block;
            color: #595959;
            text-align: center;
            margin-top: 4rem;
            margin-bottom: 4rem;
            @include max(1200px) {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }
        p{
            color: #595959;
            text-align: center;
            letter-spacing: 0.64px;
        }
    }
    .img{
        @include imgPd(491, 652);
        margin-top: 4rem;
        @include max(1200px) {
            margin-top: 2rem
        }
        img{
            @include imgCover;
        }
    }
}
.checkout-done-box{
    .checkout-done-list{
        @include flexCol;
        row-gap: 0rem;
        .checkout-done-item{
            justify-content: flex-start;
            border: 0.1rem solid $color-text;
            &:not(:last-child) {
                border-bottom: none;
            }
            .text{
                position: relative;
                font-weight: 700;
                width: 25rem;
                border-right: none;
                padding: 2rem;
                @include max(1200px) {
                    width: 18rem;
                    font-size: 1.4rem;
                    padding: 1.4rem;
                }
                @include max(575px) {
                    width: 12rem;
                }
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0.1rem;
                    height: 100%;
                    background-color: $color-text;
                }
            }
            .num{
                flex: 1;
                font-weight: 500;
                padding: 2rem;
                @include max(1200px) {
                    padding: 1.4rem;
                    font-size: 1.4rem;
                }
            }
            .image-qr-code{
                width: 20rem;
                flex: none;
                @include max(575px) {
                    width: 15rem;
                }
                @include max(320px) {
                    width: 12rem;
                }
            }
        }
    }

}
.ct-sect{
    overflow: hidden;

}
.ct-content {
    position: relative;
    z-index: 2;
    .bg{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        inset: -16rem 0 0 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .ct-heading{
        margin-bottom: 8rem;
        .tt-sect{
            margin-bottom: 3.2rem;
            @include max(1200px) {
                margin-bottom: 1.6rem;
            }
        }
        .info-list{
            margin-bottom: 3.2rem;
            @include max(1200px) {
                margin-bottom: 1.6rem;
            }
        }
        .social-list{
            .social-link{
                margin-right: 1.6rem;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        @include max(1200px) {
            margin-bottom: 4rem;
        }
    }
    .ct-map-wr{
        @include imgPd(607, 1216);
        iframe{
            @include imgCover;
        }
    }
}
.info-form{
    width: 100%;
    overflow: hidden;
}
.info-form-row{
    @include row(1.6);
    @include max(1200px) {
        margin: 0 -0.8rem;
    }
    @include max(767px) {
        margin: 0;
    }
    .info-form-thumb{
        width: calc(5/12*100%);
        padding: 0 1.6rem;
        padding-right: 0;
        @include max(1200px) {
            padding: 0 0.8rem;
            padding-right: 0;
        }
        @include max(767px) {
            width: 80%;
            margin: 0 auto;
            padding: 0;
        }
        @include max(575px) {
            width: 100%;
        }
        .thumb{
            @include imgPd(691, 520);
            height: 100%;
            @include max(767px) {
                height: auto
            }
            img{
                @include imgCover;
            }
        }
    }
    

    .info-form-wr {
        width: calc(7/12*100%);
        padding: 0 1.6rem;
        @include flexCol;
        justify-content: center;
        background-color: #F0F0F0;
        padding-left: 0;
        @include max(1200px) {
            padding: 0 0.8rem;
            padding-left: 0;
            background-color: transparent;
        }
        @include max(767px) {
            width: 80%;
            margin: 0 auto;
            padding: 0;
        }
        @include max(575px) {
            width: 100%;
        }
        .info-form-wr-w{
            width: calc(5.5/7*100%);
            height: 100%;
            margin: 0 auto;
            padding: 4rem 0;
            @include max(1200px) {
                padding: 2rem;
                width: 100%;
                background-color: #F0F0F0;
            }
            // @include max(767px) {
            //     background-color: #F0F0F0;
            // }
        }
        .tt-sect{
            color: $color-pri;
            margin-bottom: 2.4rem;
            text-transform: uppercase;
            @include max(1200px) {
                margin-bottom: 1.4rem;
            }
        }
        .desc{
            color: #454545;
            font-weight: 300;
        }
    }
    .group-form-list{
        width: 100%;
        margin-top: 3rem;
        row-gap: 4rem;
        @include max(1200px) {
            margin-top: 1.6rem;
            row-gap: 2rem;
        }
    }
    .btn{
        margin-top: 3rem;
    }
}
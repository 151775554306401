
.bn-home-slider{
    .swiper-slide{
        height: auto;
        &.swiper-slide-active{
            .bn-home-content-wr{
                .tt{
                    animation: showUp 1s ease-in-out forwards;
                }
                .desc{
                    animation: showUp 1s ease-in-out forwards;
                    animation-delay: 0.2s;
                }
                .btn{
                    animation: showUp 1s ease-in-out forwards;
                    animation-delay: 0.4s;
                }
            }
        }
    }
    .bn-home-content{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            z-index: -1;
            inset: 0;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 36.92%, rgba(0, 0, 0, 0.67) 54.47%, #000 84.69%);
            @include max(575px) {
                background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.67) 0%, #000 100%);
            }
        }
        .bg{
            position: absolute;
            z-index: -2;
            inset: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .bn-home-content-wr{
            // padding: 22.3rem 0;
            width: calc(7/12*100%);
            @include flexCol;
            justify-content: center;
            min-height: calc(100vw * 700/1728);
            @include max(1200px) {
                width: calc(10/12*100%);
                min-height: auto;
                padding: 14rem 0;
            }
            @include max(575px) {
                width: 100%;
                padding: 5rem 0;
            }
            .tt{
                color: $white;
                font-weight: 800;
                font-style: italic;
                margin: 0;
                @include hide();
            }
            .desc{
                color: $white;
                margin-bottom: 3.2rem;
                margin-top: 0;
                @include hide();
                @include max(1200px) {
                    margin-bottom: 2rem;
                }
            }
            .btn{
                @include hide();
            }
        }
    }
}
.time-start {
    position: relative;
    background-color: $color-pri;
    .decor-light {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        pointer-events: none;
        .decor {
            height: 100%;
            position: absolute;
            top: 0;
            img{
                height: 100%;
            }
            &.lf{
                left: 0;
            }
            &.rt{
                right: 0;
            }
        }
    }
    .time-start-wr {
        padding: 2.2rem 0;
        .box-count-down{
            .txt{
                font-size: 2.4rem;
                margin-bottom: 1.6rem;
                text-align: center;
                @include max(1200px) {
                    font-size: 2rem;
                }
            }
            .num-list{
                justify-content: center;
            }
            .num-item{
                &:not(:last-child){
                    padding-right: 4.8rem;
                    margin-right: 4.8rem;
                    @include max(1200px) {
                        padding-right: 2.4rem;
                        margin-right: 2.4rem;
                    }
                    @include max(575px) {
                        padding-right: 1.2rem;
                        margin-right: 1.2rem;
                    }
                }
                .num{
                    font-size: 6.4rem;
                    @include max(1200px) {
                        font-size: 5rem;
                    }
                    @include max(575px) {
                        font-size: 2.8rem;
                    }
                }
                .txt{
                    font-size: 2rem;
                    @include max(575px) {
                        font-size: 1.2rem;
                    }
                }
            }
            
        }
    }
}

.runs{
    position: relative;
    .bg{
        position: absolute;
        z-index: -1;
        inset: -30rem 0 0 0;
        width: 100%;
        height: 150%;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .main-content{
        width: calc(5/12*100%);
        // @include flexCol;
        // justify-content: space-between;
        @include max(1200px) {
            width: calc(6/12*100%);
        }
        @include max(700px) {
            width: 100%;
            .tag-sect{
                margin: 0 auto 1.2rem;
            }
        }
        .tt-sect{
            margin-bottom: 8rem;
            @include max(1200px) {
                margin-bottom: 4rem;
            }
        }
        .content-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1.6rem;
            border-bottom: 0.1rem solid #D9D9D9;
            transition: 0.3s ease-in-out;
            margin-bottom: 1.6rem;
            &:last-child{
                margin-bottom: 0;
                padding-top: 0;
            }
            &:hover{
                .name{
                    color: $color-pri;
                }
                .txt{
                    color: $color-pri;
                }
                .ic{
                    clip-path: inset(0 0 0 0);
                    visibility: visible;
                }
            }
            .ic{
                visibility: hidden;
                clip-path: inset(0 100% 0 0);
                transition: 0.3s ease-in-out;
                i{
                    font-size: 2rem;
                    font-weight: 500;
                    color: $color-pri;
                }
            }
            .name{
                font-size: 1.8rem;
                margin-bottom: 0.4rem;
                transition: 0.3s ease-in-out;
            }
            .txt{
                transition: 0.3s ease-in-out;
            }
        }
    }
    .thumb-content{
        width: calc(6/12*100%);
        @include max(700px) {
            width: 100%;
        }
        .thumb{
            @include imgPd(606, 591);
            height: 100%;
        }
        .thumb-content-slider{
            height: 100%;
        }
        .swiper{
            height: 100%;
        }
    }
}

.over-route {
    position: relative;
    z-index: 2;
    .over-route-heading{
        margin-bottom: 8rem;
        align-items: center;
        @include max(1200px) {
            margin-bottom: 4rem;
        }
        .over-route-heading-lf{
            line-height: normal;
            @include max(1200px) {
                padding: 0 0.8rem;
            }
            @include max(700px) {
                width: 100%;
            }
            .tag-sect{
                @include max(700px) {
                    margin: 0 auto 1.2rem;
                }
            }
            .tt-sect{
                @include max(700px) {
                    text-align: center;
                }
                br{
                    @include max(1200px) {
                        display: none;
                    }
                }
            }
        }
        .over-route-heading-rt{
            @include max(1200px) {
                padding: 0 0.8rem;
            }
            @include max(700px) {
                width: 100%;
            }
        }
    }
    .map-route {
        position: relative;
        padding: 9rem 0;
        @include max(1200px) {
            padding: 4rem 0;
        }
        @include max(575px) {
            padding: 1.2rem 0;
        }
        .img{
            position: absolute;
            z-index: -1;
            inset: 0;
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .map-content{
            position: relative;
            width: calc(10/12*100%);
            margin: 0 auto;
            .decor{
                position: absolute;
                z-index: -1;
                background-color: $color-pri;
                .txt{
                    display: block;
                    padding: 1.6rem 3.2rem;
                    color: $white;
                    font-weight: 700;
                    text-transform: uppercase;
                    @include max(1200px) {
                        padding: 1rem;
                        font-size: 1.4rem;
                    }
                }
                &.start{
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);

                }
                &.finish{
                    right: 0;
                    bottom: 20%;
                }
            }
        }
        .info-tourna-item{
            margin-bottom: 7rem;
            @include max(1200px) {
                margin-bottom: 2rem;
            }
            &:last-child{
                margin-bottom: 0;
            }
            .num{
                color: $color-pri;
                font-size: 7.2rem;
                text-shadow:  0.5rem 0rem $black;

                font-family: var(--font-third);
                font-weight: 700;
                @include max(1200px) {
                    font-size: 4.2rem;
                }
            }
            .txt{
                color: #0C0101;
                font-weight: 600;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

.total-prize{
    margin-top: -38rem;
    @include max(1200px) {
        margin-top: -20rem;
    }
    .decor-light{
        width: 100%;
        // left: 50%;
        // transform: translate(-50%, 0);
        .decor{
            img{
                width: 100%;
            }
        }
    }
    .banner-decor-wr{
        padding: 40rem 0 17.5rem;
        margin-top: 9rem;
        @include max(1200px) {
            margin-top: 4rem;
            padding: 25rem 0 10.5rem;
            ;
        }
        .decor.trophy{
            left: -5%;
            top: 65%;
            @include max(1200px) {
                left: 0;
            }
        }
    }
    .banner-decor {
        .banner-decor-content {
            width: calc(6.5/12*100%);
            @include max(991px) {
                width: calc(9/12*100%);
            }
            @include max(575px) {
                width: 100%;
            }
            .tt-sect{
                br{
                    @include max(700px) {
                        display: none;
                    }
                }
            }
        }
    }
}

.info-tourna-select{
    padding: 0 17.6rem;
    margin-bottom: 6rem;
    @include max(1200px) {
        margin-bottom: 3rem;
        padding: 0;
    }
}
.info-select-wr{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include max(1200px) {
        flex-wrap: wrap;
        margin: 0 -0.8rem;
    }
    @include max(650px) {
        row-gap: 1.4rem;
    }
    .info-select-op{
        @include max(1200px) {
            width: calc(7/12*100%);
            padding: 0 0.8rem;
        }
        @include max(650px) {
            width: 100%;
        }
        .select2-container .select2-selection--single {
            background-color: #F0F0F0 !important;
            padding: 2rem;
            height: auto;
            @include max(1200px) {
                padding: 1.2rem;
            }
        }
        .select2-container .select2-selection__rendered {
            @include max(1200px) {
                font-size: 1.6rem !important;
            }
            @include max(650px) {
                font-size: 1.4rem !important;
            }
        }
    }
    .info-select-resut {
        @include max(1200px) {
            width: calc(5/12*100%);
            padding: 0 0.8rem;
        }
        @include max(650px) {
            width: 100%;
        }
        .inner{
            padding: 2rem;
            background-color: $color-second;
            @include max(1200px) {
                padding: 1.2rem;
            }
            .info-select-price{
                font-size: 2rem;
                font-weight: 700;
                display: none;
                @include max(1200px) {
                    font-size: 1.6rem;
                }
                @include max(650px) {
                    font-size: 1.4rem;
                }
                &.active{
                    display: block;
                }
            }
        }
    }
}

.sign-detail-wr{
    overflow: hidden;
    .tt-sect{
        color: $color-pri;
        text-align: center;
        margin-bottom: 4rem;
        @include max(1200px) {
            margin-bottom: 2rem;
        }
    }
    .sign-detail-wr-tt-second{
        display: none;
    }
}

.info-sign {
    margin-top: 8rem;
    @include max(1200px) {
        margin-top: 4rem;
    }
}

.sign-option{
    .tab-sign{
        background-color: $color-pri;
    }
    .tab-sign-wr{
        width: calc(10/12*100%);
        margin: 0 auto;
        padding: 2rem 0;
        @include flexSpc;
        flex-wrap: wrap;
        @include max(1200px) {
            width: 100%;
            padding: 2rem;
        }
        @include max(767px) {
            padding: 1rem;
        }
        .sign-tab-btn{
            width: calc(4.2/12*100%);
            transition: all 0.4s;
            text-align: center;
            padding: 1.6rem 0;
            cursor: pointer;
            transition: all .3s ease-out;
            @include max(1200px) {
                width: 50%;
            } 
            @include max(767px) {
                padding: 1rem 0;
            }
            @include max(374px) {
                width: 100%;
            }
            &:hover{
                background-color: $white;
                .txt{
                    color: $color-pri;
                }
            }
            &.active{
                background-color: $white;
                .txt{
                    color: $color-pri;
                }
            }
            .txt{
                font-size: 2rem;
                font-weight: 700;
                color: $white;
                text-align: center;
                transition: all .3s ease-out;
                @include max(1200px) {
                    font-size: 1.8rem;
                }
                @include max(767px) {
                    font-size: 1.6rem;
                }
                @include max(575px) {
                    font-size: 1.4rem;
                }
                @include max(374px) {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .sign-select{
        width: calc(3/12*100%);
        @include max(575px) {
            width: 100%;
        }
    }
}
.sign-select-wr {
    @include flexSpc;
    flex-wrap: wrap;
    width: calc(8/12*100%);
    margin: 2.4rem auto 6rem;
    row-gap: 1.2rem;
    @include max(1200px) {
        width: 100%;
        margin: 2.4rem auto 3rem;
    }
    @include max(575px) {
        width: 100%;
    }
    .txt{
        font-size: 2rem;
        font-weight: 600;
        @include max(1200px) {
            font-size: 1.8rem;
        }
        @include max(767px) {
            font-size: 1.6rem;
        }
        @include max(575px) {
            font-size: 1.4rem;
        }
    }
    .select2-selection__rendered{
        @include max(1200px) {
            font-size: 1.8rem !important;
        }
        @include max(767px) {
            font-size: 1.6rem !important;
        }
        @include max(575px) {
            font-size: 1.4rem !important;
        }
    }
}

.form-sign-container{
    margin-top: 6rem;
    @include max(1200px) {
        margin-top: 3rem;
    }
    .form-sign-info-item-wr{
        .form-group-list{
            justify-content: space-between
        }
        .group-form{
            @include item(4.3,10,1.6);
            @include max(1200px) {
                @include item(5,10,0.8);
            }
            @include max(767px) {
                @include item(6,12,0.8);
            }
            @include max(600px) {
                width: 100%;
            }
            >label{
                display: flex;
                font-weight: 600;
                margin-bottom: 1.2rem;
            }
            >.woocommerce-input-wrapper{
                display: flex;
                width: 100%;
                height: 5rem;
                @include max(1200px) {
                    height: 4rem;
                }
                input{
                    font-size: 1.6rem;
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    border: none;
                    padding: 0 2.4rem;
                    @include max(1200px) {
                        padding: 0 1.2rem;
                        font-size: 1.4rem;
                    }
                    &::placeholder{
                        color: #BFBFBF;
                        @include max(1200px) {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
            .required{
                color: #E51B1B;
            }
        }
    }
}

.woocommerce-checkout #payment {
    background: $grey-sec;
    border-radius: 0;
}

// style form detail

.form-sign-info-flex{
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    @include max(767px) {
        flex-wrap: wrap;
    }
}

.form-sign-info-lf{
    width: 100%;
    transition: all .4s linear;
    .form-sign-info-item-wr{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            inset: 0;
            z-index: 2;
            display: none;
        }
    }
}
.form-sign-info-right{
    width: 50%;
    padding: 0 0.8rem;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    position: absolute;
    pointer-events: none;
    /* display: none; */
    top: 0;
    right: 0;
    transition: all .4s linear;
    @include max(767px) {
        width: 100%;
        margin-top: 4rem;
    }
    .form-pay{
        @include max(767px) {
            width: 100%;
        }
    }
    .btn{
        .text{
            color: $white;
            font-size: 1.6rem;
            font-weight: 700;
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }
}

// custom form after submit form 

.sign-detail-wr.custom-form {
    .sign-detail-wr-tt-first{
        display: none;
    }
    .sign-detail-wr-tt-second{
        display: block;
    }
    .form-sign-info-heading{
        display: none;
    }
    .form-sign-info-lf{
        width: 50%;
        @include max(767px) {
            width: 100%;
        }
        .form-sign-info-item-wr{
            .group-form {
                width: 100%;
            }
        }
    }
    .form-sign-info-right{
        position: unset;
        opacity: 1;
        visibility: visible;
        transform: translate(0,0);
        pointer-events: auto;
        // .btn-pri{
        //     &.hidden{
        //         pointer-events: none;
        //         &::after{
        //             content: '';
        //             position: absolute;
        //             z-index: 10;
        //             inset: 0;
        //             width: 100%;
        //             height: 100%;
        //             background: rgba(255,255,255,0.5);
        //         }
        //     }
        // }
    }
    .form-sign-info-item-wr{
        &::before{
            display: block;
        }
        .group-form {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            @include max(575px) {
                flex-direction: column;
                margin: 0;
            }
            > label {
                width: 20rem;
                padding: 0 ;
                font-weight: 500;
                margin-bottom: 0;
                @include max(1200px) {
                    width: 14rem;
                }
                @include max(575px) {
                    font-size: 1.4rem;
                    width: 100%;
                    margin-bottom: 0.8rem;
                    padding: 0;
                }
                .required{
                    display: none;
                }
            }
            > .woocommerce-input-wrapper{
                width: auto;
                padding: 0 ;
                height: auto;
                flex: 1;
                @include max(575px) {
                    width: 100% ;
                    padding: 0;
                }
                input{
                    color: $color-text !important;
                    font-weight: 600;
                    word-break: break-all;
                    background-color: transparent;
                    padding: 0;
                    @include max(575px) {
                        font-size: 1.4rem;
                    }
                    &::placeholder{
                        display: none;
                    }
                }
            }
            .select2{
                width: 20rem !important;
                background-color: transparent;
                height: auto;
                padding: 0;
                @include max(1200px) {
                    width: 14rem !important;
                }
                @include max(575px) {
                    width: 100% !important;
                }
                .select2-selection__arrow{
                    display: none !important;
                }
                .select2-selection__rendered {
                    color: $color-text !important;
                    font-weight: 600 !important;
                    overflow: visible !important;
                    @include max(575px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
        #billing_address_2_field {
            > label {
                width: 20rem !important;
                @include max(1200px) {
                    width: 14rem !important;
                }
                @include max(575px) {
                    width: 100% !important;
                }
            }
            
        }
    }
}

.sign-sect{
    overflow: hidden;
    position: relative;
    .bg{
        position: absolute;
        z-index: -1;
        img{
            width: 100%;
            height: 100%;
        }
        &.up{
            width: 100%;
            height: 40%;
            inset: 0;
        }
        &.bot{
            width: 100%;
            height: 40%;
            inset: auto 0 0 0;
        }
    }
}
.sign-box{
    margin-bottom: 20rem;
    &:last-child{
        margin-bottom: 0;
    }
    @include max(1200px) {
        margin-bottom: 10rem
    }
    @include max(767px) {
        margin-bottom: 5rem
    }
    &:nth-child(even){
        .tourna-item{
            flex-direction: row-reverse;
            @include max(800px) {
                flex-direction: column-reverse;
            }
            
        }
    }
}
.tourna-item{
    @include row(1.6);
    justify-content: space-between;
    align-items: center;
    @include max(1200px) {
        @include row(0.8)
    }
    @include max(799px){
        flex-direction: column-reverse;
    }
    .tourna-thumb{
        @include item(7,12,1.6);
        @include max(1200px) {
            @include item(7,12,0.8);
        }
        @include max(799px) {
            width: 80%;
            margin: 0 auto;
        }
        @include max(500px) {
            width: 100%
        }
        .thumb{
            @include imgPd(379, 696);
            @include max(799px) {
                margin-bottom: 1.6rem;
            }
            &:hover{
                img{
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
            img{
                @include imgCover;
                transition: all .2s ease-in-out
            }
        }
    }
    .tourna-content{
        @include item(4,12,1.6);
        @include max(1200px) {
            @include item(5,12,0.8);
        }
        @include max(799px) {
            width: 80%;
            margin: 0 auto;
        }
        @include max(500px) {
            width: 100%
        }
        .tt-sect{
            margin-bottom: 1.6rem;
            @include max(1200px) {
                font-size: 1.8rem;
            }
            @include max(500px) {
                font-size: 1.6rem;
                text-wrap: pretty;
            }
        }
        .desc{
            margin-bottom: 1.6rem;
            color: #0C0101;
            @include lc(5);
            @include max(1200px) {
                font-size: 1.4rem;
            }
            @include max(500px) {
                font-size: 1.2rem;
            }
        }
        .price{
            font-size: 2rem;
            font-weight: 700;
            @include max(1200px) {
                font-size: 1.6rem;
            }
        }
        .btn{
            margin-top: 3.2rem;
            width: auto;
            @include max(1200px) {
                margin-top: 1.6rem;
            }
            @include max(800px) {
                margin-left: auto;
            }
        }
    }
}
.blog-detail{
    position: relative;
    .bg{
        position: absolute;
        z-index: -1;
        top: -20rem;
        left: 0;
        right: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.blog-detail-container{
    width: 100%;
    padding: 0 3rem;
    @include max(1200px) {
        padding: 0 1.5rem;
    }
    .blog-detail-wr{
        justify-content: space-between;
    }
    .aside {
        width: calc(2.5/12*100%);
        padding: 0 1.6rem;
        @include max(1200px) {
            width: calc(2.8/12*100%);
            padding: 0 0.8rem;
        }
        @include max(800px) {
            width: 30rem;
            // height: 100%;
            padding: 0;
        }
        @include max(575px) {
            width: 85%;
        }
        .aside-wr{
            max-height: 80%;
            overflow: auto;
            // height: 100%;
        }
        .ez-toc-icon-toggle-span{
            @include max(800px) {
                display: none;
            }
        }
    }

    .aside.aside-img{
        @include max(800px){
            margin: 0 auto;
        }
        .thumb{
            @include imgPd(540, 330);
            img{
                @include imgCover;
            }
        }
    }

    .main-content{
        width: calc(6.4/12*100%);
        padding: 0 1.6rem;
        @include max(1200px) {
            padding: 0 0.8rem;
        }
        @include max(800px) {
            width: 100%;
        }
    }

}

.aside-wr {
    .aside-heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem 1.6rem;
        background-color: #222;
        color: $white;
        cursor: pointer;
        @include max(1200px) {
            padding: 0.8rem 1rem;
        }
        .txt{
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
        .ic{
            @include max(800px) {
                display: none;
            }
            i{
                font-size: 1.8rem;
                color: $white;
                transition: all .2s ease-in-out;
            }
            &.active{
                i{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .menu-list{
        .menu-link{
            display: flex;
            padding: 1.2rem 1.6rem;
            color: #000;
            border-bottom: 0.1rem solid #DBDBDB;
            background-color: rgba(245, 245, 245, 1);
            transition: all .2s ease-in-out;
            @include max(1200px) {
                font-size: 1.4rem;
                padding: 0.8rem 1rema;
            }
            &:hover{
                background-color: rgba(220 ,220 ,220 , 1);
            }
        }
    }
}

.mona-content{
    #ez-toc-container{
        display: none;
    }
    .info-list{
        .info-item{
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            font-weight: 500;
            color: #A0A0A0;
            @include max(1200px) {
                font-size: 1.2rem
            }
        }
        .ic{
            @include icon(flex ,2.4, 2.4);
            margin-right: 0.8rem;
            @include max(1200px) {
                width: 1.6rem;
                height: 1.6rem;
                margin-right: 0.4rem;
            }
        }
    }
    h1{
        color: #3A3A3A;
        font-size: 3rem;
        font-weight: 800;
        text-transform: uppercase;
        @include max(1200px) {
            font-size: 2.4rem;
        }
    }
    p{
        color: $color-text;
        font-size: 1.6rem;
        font-weight: 400;
        @include max(1200px) {
            font-size: 1.4rem;
        }
    }
    strong{
        color: $color-text;
    }
    .thumb{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .thumb-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: 3.2rem;
        @include max(1200px) {
            gap: 1.6rem;
        }
        .thumb{
            &:first-child{
                grid-column: 1/2;
                grid-row: 1/3;
            }
            &:nth-child(2){
                grid-column:2/3;
                grid-row: 1/2;
            }
            &:last-child{
                grid-column: 2/3;
                grid-row: 2/3;
            }
        }
    }
    .wp-block-image{
        .aligncenter{
            width: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

#ez-toc-container{
    width: 100%;
    // height: 100%;
    padding: 0;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    a{
        &:hover{
            text-decoration: none;
        }
    }
    .ez-toc-title-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #222;
        padding: 0.8rem 1.6rem;
        .ez-toc-title {
            font-size: 1.6rem;
            color: $white;
            font-weight: 600;
        }
        .ez-toc-pull-right{
            padding: 0;
            margin: 0;
        }
        .ez-toc-js-icon-con{
            position: unset;
            float: none;
            border-radius: 0;
            border: none;
            width: 100%;
        }
        .ez-toc-icon-toggle-span{
            &::after{
                content: "\f0d7";
                font-family: "Font Awesome 6 Pro";
                font-weight: 900;
                color: $white;
                transition: all .3s;
            }
            svg{
                display: none;
            }
        }
    }
    .ez-toc-list {
        display: block;
        li{
            font-size: 1.6rem;
            border-bottom: 0.1rem solid #DBDBDB;
            &:last-child{
                border-bottom: none;
            }
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
        .ez-toc-link{
            color: $black;
            background-color: rgba(245, 245, 245, 1);
            display: flex;
            padding: 1.2rem 1.6rem;
            transition: all .3s ease-in-out;
            &:hover{
                text-decoration: none;
                background-color: rgba(220 ,220 ,220 , 1);
            }
            &.active{
                background-color: rgba(220 ,220 ,220 , 1);
            }
        }
    }
    &.toc_close{
        .ez-toc-title-container{
            .ez-toc-icon-toggle-span{
                &::after{
                    transform: rotate(180deg);
                }
            }
        }
    }
}
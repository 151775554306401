.partners-sup-wr{
    .tt-sect{
        margin-bottom: 8rem;
        @include max(1200px) {
            margin-bottom: 4rem;
        }
    }
    .partners-units{
        width: calc(10/12*100%);
        margin: 0 auto;
        @include max(1200px) {
            width: 100%;
        }
    }
    .partners-units-wr{
        @include row(3);
        row-gap: 10rem;
        @include max(1200px) {
            margin: 0 -1.5rem;
            row-gap: 5rem;
        }
        @include max(650px) {
            margin: 0 -0.8rem;
        }
        .partners-units-item{
            width: calc(6/12*100%);
            padding: 0 3rem;
            @include max(1200px) {
                padding: 0 1.5rem;
            }
            @include max(650px) {
                width: 100%;
                padding: 0 0.8rem;
            }
            .tt-sect{
                margin-bottom: 4rem;
                @include max(1200px) {
                    font-size: 1.6rem;
                    margin-bottom: 2rem;
                }
            }
            .units-brand-list{
                @include row(3);
                @include max(1200px) {
                    margin: 0 -1.5rem;
                }
                @include max(650px) {
                    margin: 0 -0.8rem;
                }
            }
            .units-brand {
                width: calc(3/6*100%);
                min-height: 15.6rem;
                padding: 0 3rem;
                border-radius: 0.4rem;
                @include max(1200px) {
                    padding: 0 1.5rem;
                }
                @include max(650px) {
                    padding: 0 0.8rem
                }
                .units-brand-inner{
                    @include flexCenter;
                    width: 100%;
                    height: 100%;
                    border: 0.1rem solid #EDEDED;
                    transition: 0.3s ease-in-out;
                    &:hover{
                        box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.10), 0px 4px 6px -4px rgba(16, 24, 40, 0.10);
                    }
                }
                .img{
                    width: auto;
                    height: 10rem;
                    @include min(2000px) {
                        height: 7rem;
                    }
                    // @include max(1200px) {
                    //     height: 8rem;
                    // }
                    // @include max(374px) {
                    //     height: 5rem;
                    // }
                    img{
                        height: 100%;
                    }
                }
            }
        }
    }
}
.partners-list-sect{
    overflow: hidden;
}
.partners-list{
    .swiper{
        margin: 0 -3rem;
        @include max(1200px) {
            margin: 0 -1.2rem;
        }
        @include max(575px) {
            margin: 0 -0.8rem;
        }
    }
    .swiper-wrapper{
        transition-timing-function: linear !important;
    }
    .swiper-slide {
        width: calc(2/12*100%) !important;
        padding: 0 3rem;
        @include max(1300px) {
            width: calc(2.5/12*100%) !important;
        }
        @include max(1200px) {
            padding: 0 1.2rem;
        }
        @include max(575px) {
            padding: 0 0.8rem;
        }
        @include max(991px) {
            width: calc(2.8/12*100%) !important;
        }
        @include max(767px) {
            width: calc(4/12*100%) !important;
        }
        @include max(575px) {
            width: calc(5/12*100%) !important;
        }
    }
    .brand-item{
        // @include flexCenter;
        // width: 100%;
        // height: 100%;
        .img{
            padding: 4rem 0;
            border: 0.1rem solid #EDEDED;
            transition: 0.3s ease-in-out;
        }
        .img-inner{
            @include imgPd(70, 176);
            img{
                @include imgCover;
            }
        }
        .txt{
            font-size: 1.4rem;
            font-weight: 600;
            @include max(1200px) {
                font-size: 1.2rem;
            }
        }
        text-align: center;
    }
    .partners-list-up{
        margin-bottom: 4rem;
    }
}

.reason{
    position: relative;
    .bg{
        position: absolute;
        z-index: -1;
        inset: -10rem 0 0 0;
        width: 100%;
        height: 190%;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
.page-sign-detail{
    .woocommerce-cart-form{

    }
    .woocommerce-cart-form-custom{
        padding-bottom: 4rem;
        .woocommerce-cart-form{
            border: 0.1rem solid #e9e6ed;
            margin-bottom: 4rem;
            padding: 2rem;
            .shop_table{
                margin-bottom: 0;
                thead{
                    color: $color-text;
                }
                tbody{
                    td{
                        // padding: 1.2rem 0;
                        @include max(575px) {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
            .coupon{
                display: flex;
                width: 50%;
                @include max(1024px) {
                    width: 100%;
                }
                input{
                    width: 100% !important;
                    height: 5.2rem !important;
                    padding: 1rem 1.5rem !important;
                    font-weight: 600;
                    @include max(1200px) {
                        height: 4rem !important;
                        width: 70% !important;
                    }
                    &::placeholder{
                        font-weight: 600;
                    }
                }
                .button{
                    flex: none;
                    color: $white;
                }
            }
            .button{
                flex: 1;
                color: $white;
                .txt{
                    position: relative;
                    z-index: -1;
                }
            }
            .actions{
                margin-top: 3rem;
                @include max(767px) {
                    padding: 0.9rem 0;
                }
            }
        }
        .cart-collaterals{
            border: 0.1rem solid #e9e6ed;
            padding: 2rem;
            thead{
                color: $color-text;
            }
            .cart_totals {
                width: 100%;
                float: none;
            }
            h2{
                font-size: 1.6rem;
                color: $color-text;
                background-color: $white;
                font-weight: 700;
                padding: 0.9rem 0;
                @include max(767px) {
                    text-align: center;
                }
                @include max(575px) {
                    font-size: 1.4rem;
                }
            }
            .btn{
                display: flex !important;
                text-align: center;
                margin-bottom: 0 !important;
                margin-left: auto;
                @include max(767px) {
                    width: 100%;
                }
                // font-size: ;
                // padding: 0 !important;
            }
            .cart_totals{
                height: 100%;
                flex-direction: column;
                display: flex;
                tbody{
                    td{
                        // padding: 1.2rem 0;
                        text-align: center;
                        @include max(575px) {
                            font-size: 1.4rem;
                        }
                    }
                }
                .wc-proceed-to-checkout{
                    margin-top: auto;
                    padding: 0.9rem 0 ;
                }
                .shop_table{
                    margin-bottom: 0;
                }
            }
        }
        table.shop_table{
            border: none;
        }
        table.shop_table th{
            background-color: $white;
            color: $color-text;
        }
        tbody{
            color: $color-text;
            font-weight: 600;
            .product-remove{
                a.remove{
                    color: $color-pri !important;
                    &:hover{
                        background: transparent;
                    }
                }
            }
        }
    }
}


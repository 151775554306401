.ft{
    position: relative;
    z-index: 2;
    overflow: hidden;
    background-color: $red-wine;
    .bg{
        position: absolute;
        z-index: -1;
        inset: 3rem 0 0 0 ;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            @include max(1200px) {
                object-fit: cover;
            }
        }
    }
    .ft-main{
        padding: 6.5rem 0 12rem;
        @include max(1200px) {
            padding: 3rem 0;
        }
    }
    .ft-wr{
        justify-content: space-between;
    }
}

.ft-item{
    color: $white;
    &:first-child{
        @include item(3.25, 12,1.6);
        @include max(1200px) {
            @include item(3, 12, 0.8);
        }
        @include max(900px) {
            @include item(6,12,0.8)
        }
        @include max(374px) {
            width: 100%
        }
        p{
            // display: none;
            // @include lc(5);
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }
    &:nth-child(2){
        @include item(2.25, 12,1.6);
        @include max(1200px) {
            @include item(3, 12, 1.6);
        }
        @include max(900px) {
            @include item(6,12,0.8)
        }
        @include max(374px) {
            width: 100%
        }
    }
    &:nth-child(3){
        @include item(2.5, 12,1.6);
        @include max(1200px) {
            @include item(3, 12, 1.6);
        }
        @include max(900px) {
            @include item(6,12,0.8)
        }
        @include max(374px) {
            width: 100%
        }
    }
    &:last-child{
        @include item(4, 12,1.6);
        @include max(1200px) {
            @include item(3, 12, 1.6);
        }
        @include max(900px) {
            @include item(6,12,0.8)
        }
        @include max(374px) {
            width: 100%
        }
        p{
            @include lc(5);
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }
    .custom-logo-link{
        display: block;
        height: var(--w-logo-ft);
        margin-bottom: 2.4rem;
        @include max(1200px) {
            margin-bottom: 1.2rem
        }
        img{
            @include imgFull;
        }
    }
    .ft-tt{
        font-weight: 700;
        margin-bottom: 1.6rem;
    }
    .menu-list{
        .menu-item{
            margin-bottom: 1.6rem;
            @include max(1200px) {
                margin-bottom: 1rem;
            }
            &:last-child{
                margin-bottom: 0;
            }
            .menu-link{
                transition: .4s;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
            &:hover{
                .menu-link{
                    color: $yel-sec;
                }
            }
        }
    }
    .social-list{
        margin-top: 2.4rem;
        @include max(1200px) {
            margin-top: 1.2rem;
        }
        .social-link{
            display: block;
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1.6rem;
            transition: all .4s;
            &:hover{
                transform: translateY(-1rem);
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .box-mail{
        margin-top: 2.4rem;
    }
}

.ft-author{
    padding: 2rem 0;
    border-top: 0.1rem solid rgba(255, 255, 255, 1);
    .ft-author-wr{
        text-align: center;
    }
    .link{
        font-family: var(--font-sec);
        color: $white;
        font-size: 1.4rem;
        font-weight: 700;
        display: inline-flex;
        justify-content: center;
        @include max(320px) {
            font-size: 1.2rem;
            align-items: center;
        }
        .img{
            display: block;
            max-width: 18rem;
            margin-left: 0.5rem;
            @include max(320px) {
                max-width: 16rem;
            }
            img{
                vertical-align: middle;
            }
        }
    }
}
.blog-slider{
    position: relative;
    .bg{
        position: absolute;
        z-index: -1;
        inset: -30rem 0 0 0;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .blog-slider-wr {
        padding-top: 8rem;
        @include max(1200px) {
            padding-top: 4rem;
        }
    }
    .swiper-pagination{
        padding-top: 8rem;
        @include max(1200px) {
            padding-top: 4rem;
        }
    }
}
.blog-grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: auto;
    gap: 3.2rem;
    @include max(1200px) {
        gap: 1.6rem;
    }
    @include max(767px) {
        display: flex;
        flex-wrap: wrap;
    }
    .blog-item{
        .item-inner{
            height: 100%;
        }
        .thumb{
            width: 40%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            // height: 18rem;
            // flex-shrink: 0;
        }
        .content{
            width: 60%;
            height: 100%;
            padding-bottom: 2rem;
            .tt{
                font-size: 1.4rem;
            }
        }
        &:first-child{
            grid-column: 1/2;
            grid-row: 1/4;
            .thumb{
                width: 100%;
                height: auto;
                @include imgPd(425, 590);
                img{
                    @include imgCover;
                }
            }
            .content{
                width: 100%;
                height: auto;
                padding: 2rem 2rem 1.6rem 2rem;
                .cate{
                    font-size: 1.6rem;
                }
                .tt{
                    font-size: 1.8rem;
                }
                .desc{
                    font-size: 1.6rem;
                }
            }
        }
        &:nth-child(2){
            grid-column: 2/3;
            grid-row: 1/2;
            .item-inner{
                display: flex;
                align-items: center;
                gap: 2.3rem;
                @include max(1200px) {
                    gap: 1.2rem;
                } 
            }
        }
        &:nth-child(3){
            grid-column: 2/3;
            grid-row: 2/3;
            .item-inner{
                display: flex;
                align-items: center;
                gap: 2.3rem;
                @include max(1200px) {
                    gap: 1.2rem;
                } 
            }
        }
        &:last-child{
            grid-column: 2/3;
            grid-row: 3/4;
            .item-inner{
                display: flex;
                align-items: center;
                gap: 2.3rem;
                @include max(1200px) {
                    gap: 1.2rem;
                } 
            }
        }
    }
}
.blog-list-sect{
    position: relative;
    .bg{
        position: absolute;
        z-index: -1;
        inset: auto 0 0 0;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .tt-sect{
        margin-bottom: 8rem;
        @include max(1200px) {
            margin-bottom: 4rem;
        }
    }
    .btn{
        margin-top: 8rem;
        @include max(1200px) {
            margin-top: 4rem
        }
    }
}
.blog-list {
    @include row(1.6);
    row-gap: 8rem;
    @include max(1200px) {
        margin: 0 -0.8rem;
        row-gap: 4rem
    }
    .item{
        @include item(4,12 ,1.6);
        @include max(1200px) {
            padding: 0 0.8rem
        }
        @include max(767px) {
            width: 50%;
        }
        .thumb{
            @include imgPd(384, 384);
            img{
                @include imgCover;
            }
        }
        .content{
            padding: 2.4rem 1.6rem 1.6rem 1.6rem;
            height: 100%;
            @include max(1200px) {
                padding: 1.2rem;
            }
            @include max(500px) {
                padding: 0.8rem;
            }
            .cate{
                font-size: 1.6rem;
                margin-bottom: 1rem;
                @include max(1200px) {
                    font-size: 1.2rem;
                }
                @include max(500px) {
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                }
            }
            .tt{
                font-size: 1.8rem;
                @include max(1200px) {
                    font-size: 1.6rem;
                }
                @include max(500px) {
                    font-size: 1.4rem;
                }
            }
            .desc{
                font-size: 1.6rem;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
                @include max(500px) {
                    font-size: 1.2rem
                }
            }
        }
    }
    .item-inner{
        @include flexCol;
        height: 100%;
    }
}
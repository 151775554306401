.row{
    --row-gap : 2rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--row-gap);
    margin: 0 -1.6rem;
    @include max(1200px) {
        margin: 0 -0.8rem;
    }
}
.row > *{
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
}
.col{
    width: 100%;
    padding: 0 var(--it-pd);
    &-1{
        width: calc((1/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-2{
        width: calc((2/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-3{
        width: calc((3/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-4{
        width: calc((4/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-5{
        width: calc((5/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-6{
        width: calc((6/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-7{
        width: calc((7/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-8{
        width: calc((8/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-9{
        width: calc((9/12)* 100%);
        padding: 0 var(--it-pd);

    }
    &-10{
        width: calc((10/12)* 100%);
        padding: 0 var(--it-pd);
    }
    &-11{
        width: calc((11/12)* 100%);
        padding: 0 var(--it-pd);
    }
}
@media (min-width: 575px){
    .col-sm-1{
        width: calc((1/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-2{
        width: calc((2/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-3{
        width: calc((3/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-4{
        width: calc((4/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-5{
        width: calc((5/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-6{
        width: calc((6/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-7{
        width: calc((7/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-8{
        width: calc((8/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-9{
        width: calc((9/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-10{
        width: calc((10/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-sm-11{
        width: calc((11/12)* 100%);
        padding: 0 var(--it-pd);
    }
}
@media (min-width: 768px){
    .col-md-1{
        width: calc((1/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-2{
        width: calc((2/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-3{
        width: calc((3/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-4{
        width: calc((4/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-5{
        width: calc((5/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-6{
        width: calc((6/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-7{
        width: calc((7/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-8{
        width: calc((8/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-9{
        width: calc((9/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-10{
        width: calc((10/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-md-11{
        width: calc((11/12)* 100%);
        padding: 0 var(--it-pd);
    }
}
@media (min-width: 991px){
    .col-lg-1{
        width: calc((1/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-2{
        width: calc((2/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-3{
        width: calc((3/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-4{
        width: calc((4/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-5{
        width: calc((5/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-6{
        width: calc((6/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-7{
        width: calc((7/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-8{
        width: calc((8/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-9{
        width: calc((9/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-10{
        width: calc((10/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-lg-11{
        width: calc((11/12)* 100%);
        padding: 0 var(--it-pd);
    }
}
@media (min-width: 1200px){
    .col-xg-1{
        width: calc((1/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-2{
        width: calc((2/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-3{
        width: calc((3/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-4{
        width: calc((4/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-5{
        width: calc((5/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-6{
        width: calc((6/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-7{
        width: calc((7/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-8{
        width: calc((8/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-9{
        width: calc((9/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-10{
        width: calc((10/12)* 100%);
        padding: 0 var(--it-pd);
    }
    .col-xg-11{
        width: calc((11/12)* 100%);
        padding: 0 var(--it-pd);
    }
}

.d-flex{
    display: flex;
}

.d-flex-row{
    display: flex;
    flex-direction: row;
}

.d-flex-col{
    display: flex;
    flex-direction: column;
}

.bar-fixed {
    position: fixed;
    z-index: 11;
    bottom: 2.5rem;
    right: 2rem;
    transform: translate(100%, 0);
    opacity: 0;
    visibility: hidden;
    transition: all .4s linear;
    @media (max-width:575px){
        right: 1.5rem;
        bottom: 6.5rem;
    }
    .bar-fixed-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.8rem;
        height: 4.8rem;
        margin-bottom: 2rem;
        cursor: pointer;
        &:last-child{
            margin-bottom: 0;
        }
        &:not(:last-child){
            &::before, &::after{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                top: 0rem;
                left: 0rem;
                position: absolute;
                z-index: -1;
                border-radius: 50%;
                background-color: $color-pri;
                animation: scaleAni 1.8s linear infinite;
                opacity: 0;
            }
            &::after{
                animation-delay: 0.5s;
            }
            .ic{
            animation: ring-ring 2s infinite;
        }
        }
        @include max(1200px) {
            width: 3.8rem;
            height: 3.8rem;
        }
        .ic{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.4rem;
            height: 2.4rem;
            @include max(1200px) {
                width: 2rem;
                height: 2rem;
            }
            i{
                font-size: 1.6rem;
                color: $white;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .back-to-top{
        width: 4.7rem;
        height: 4.7rem;
        border: 0.1rem solid $color-pri;
        background-color: $white;
        border-radius: 50%;
        transition: all .3s linear;
        @include max(1200px) {
            width: 3.7rem;
            height: 3.7rem;
        }
        .ic{
            i{
                color: $color-pri;
                transition: all .3s linear;
            }
        }
        &:hover{
            background-color: $color-pri;
            .ic{
                i{
                    color: $white;
                }
            }
        }
    }
    &.active{
        transform: translate(0, 0);
        opacity: 1;
        visibility: visible;
    }
}

.effectShine{
    position: relative;
    overflow: hidden;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 2;
        display: block;
        content: '';
        width: 50%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
        transform: skewX(-25deg);
    }
    &:hover{
        &::before{
            animation: shine 0.8s ease-in forwards;
        }
    }
}


.social-list{
    display: flex;
    align-items: center;
}

.box-mail-wr{
    .input{
        display: flex;
        align-items: center;
        width: 100%;
        height: 4.6rem;
        background-color: $white;
        padding: 1.2rem 2.4rem;
        box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.25);
        @include max(1200px) {
            padding: 0.8rem 1rem;
            height: 3.8rem;
        }
        input{
            width: 100%;
            height: 100%;
            flex: 1;
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
        .ic{
            margin-left: 0.5rem;
            @include icon(block, 2.4, 2.4);
            @include max(1200px) {
                @include icon(block, 2, 2);
            }
        }
    }
    .label{
        input{
            display: none;
            &:checked{
                ~.box-txt{
                    .box{
                        .ic{
                            display: block;
                        }
                    }
                }
            }
        }
        .box-txt{
            display: flex;
            align-items: center;
            margin-top: 0.8rem;
            cursor: pointer;
            .box{
                position: relative;
                @include flexCenter;
                width: 1.8rem;
                height: 1.8rem;
                background-color: $white;
                margin-right: 0.8rem;
                flex-shrink: 0;
                .ic{
                    display: none;
                    width: 1.4rem;
                    height: 100%;
                    img{
                        @include imgFull;
                    }
                }
            }
            .txt{
                font-size: 1.4rem;
            }
        }
    }
}

.bn{
    position: relative;
    z-index: 2;
    &::before{
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 36.92%, rgba(0, 0, 0, 0.67) 54.47%, #000 84.69%);
    }
    .bg{
        position: absolute;
        z-index: -2;
        inset: 0;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .bn-content{
        text-align: center;
        padding: 14rem 0;
        min-height: 40rem;
        @include min(1800px) {
            padding: 18rem 0;
        }
        @include max(1200px) {
            padding: 10rem 0;
            min-height: auto;
        }
        @include max(575px) {
            padding: 7rem 0;
        }
        .tt{
            color: $color-second;
            font-weight: 800;
            font-style: italic;
            margin-bottom: 0.8rem;
            text-transform: uppercase;
        }
    }
}

.bn-sec{
    position: relative;
    .bg{
        position: absolute;
        z-index: -2;
        inset: 0;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    // .bn-sec-slider{
    //     .swiper-slide{
    //         &.swiper-slide-active{
    //             .bn-sec-content{
    //                 .tt{
    //                     animation: showUp 0.6s linear forwards;
    //                     animation-delay: 0.6s;
    //                 }
    //                 .desc {
    //                     animation: showUp 0.6s linear forwards;
    //                     animation-delay: 0.8s;
    //                 }
    //                 .box-count-down{
    //                     animation: showUp 0.6s linear forwards;
    //                     animation-delay: 1s;
    //                 }
    //                 .btn{
    //                     animation: showUp 0.6s linear forwards;
    //                     animation-delay: 1.2s;
    //                 }
    //             }
    //         }
    //     }
    // }
    .swiper-slide{
        height: auto;
        .bn-sec-wr{
            height: 100%;
        }
    }
    .bn-sec-wr{
        padding: 15rem 0;
        min-height: 67rem;
        position: relative;
        z-index: 2;
        @include max(1560px) {
            padding: 12rem 0 ;
        }
        @include max(1200px) {
            min-height: auto;
            padding: 8rem 0;
        }
        @include max(575px) {
            padding: 5rem 0;
        }
        &::before{
            content: '';
            position: absolute;
            z-index: -1;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 36.92%, rgba(0, 0, 0, 0.67) 54.47%, #000 84.69%);    
            @include max(575px) {
                background: linear-gradient(270deg, rgba(0, 0, 0, 0) -0%, rgba(0, 0, 0, 0.67) 0%, #000 84.69%);
            }
        }
    }
    .bn-sec-content{
        max-width: calc(7/12*100%);
        width: 100%;
        &.active{
            .tt{
                animation: showUp 0.6s linear forwards;
                animation-delay: 0.6s;
            }
            .desc {
                animation: showUp 0.6s linear forwards;
                animation-delay: 0.8s;
            }
            .box-count-down{
                animation: showUp 0.6s linear forwards;
                animation-delay: 1s;
            }
            .btn{
                animation: showUp 0.6s linear forwards;
                animation-delay: 1.2s;
            }
        }
        @include max(991px) {
            max-width: calc(10/12*100%);
        }
        @include max(575px) {
            max-width: 100%;
            text-align: center;
        }
        .tt{
            color: $white;
            font-weight: 800;
            font-style: italic;
            margin-bottom: 0.8rem;
            text-transform: uppercase;
            @include hide();
            transform: translateY(5rem);
        }
        .desc{
            color: $white;
            font-size: 1.8rem;
            @include hide();
            transform: translateY(5rem);
            @include max(575px) {
                font-size: 1.6rem;
            }
        }
        .box-count-down{
            margin-top: 1.6rem;
            @include hide();
            transform: translateY(5rem);
            .num-list{
                @include max(575px) {
                    justify-content: center;
                }
            }
        }
        .btn{
            margin-top: 3.2rem;
            @include hide();
            transform: translateY(5rem);
            @include max(1200px) {
                margin-top: 2rem;
            }
            @include max(575px) {
                margin: 2rem auto 0;
            }
        }
    }
}

.box-count-down{
    >.txt{
        color: $white;
        font-weight: 700;
        @include max(575px) {
            font-size: 1.4rem;
        }
    }
    .num-list{
        display: flex;
        align-items: center;
        .num-item{
            color: $white;
            text-align: center;
            @include max(575px) {
                width: calc(3/12*100%);
            }
            transition: all .3s ease-out;
            &:hover{
                .num{
                    color: $color-second;
                }
                .txt{
                    color: $color-second;
                }
            }
            &:not(:last-child){
                padding-right: 2.4rem;
                margin-right: 2.4rem;
                border-right: 0.1rem solid rgba(255, 255, 255, 0.20);
                @include max(575px) {
                    padding-right: 1.2rem;
                    margin-right: 1.2rem;
                }
            }
            .num{
                font-size: 3.6rem;
                font-weight: 800;
                margin: 0;
                letter-spacing: 3.6px;
                transition: all .3s ease-out;
                @include max(575px) {
                    font-size: 2.8rem;
                }
            }
            .txt{
                font-size: 1.2rem;
                font-weight: 700;
                transition: all .3s ease-out;
            }
        }
    }
}

.group-form{
    .group-form-inner{
        @include flexCol;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        &.imp{
            .label{
                position: relative;
                display: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 0.5rem;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
                &::after{
                    content: '*';
                    position: absolute;
                    top: 50%;
                    right: -1rem;
                    transform: translate(0, -50%);
                    display: block;
                    color: $color-pri;
                    font-size: 2rem;
                }
            }
        }
    }
    .label{
        margin-bottom: 1.2rem;
        font-weight: 600;
        .txt {
            color: $color-text;
            font-size: 1.2rem;
            font-style: italic;
            font-weight: 500;
        }
    }
    // .wpcf7-form-control-wrap{
    //     width: 100%;
    //     height: 5rem;
    //     display: flex;
    //     padding: 0rem 2.4rem;
    //     background-color: $white;
    //     @include max(1200px) {
    //         height: 4rem;
    //     }
    //     input, textarea{
    //         font-size: 1.4rem;
    //         width: 100%;
    //         height: 100%;
    //         &::placeholder{
    //             font-size: 1.4rem;
    //         }
    //     }
    // }
    .input{
        width: 100%;
        height: 5rem;
        display: flex;
        padding: 0rem 2.4rem;
        background-color: $white;
        @include max(1200px) {
            height: 4rem;
            padding: 0rem 1.2rem;
        }
        input{
            font-size: 1.6rem;
            width: 100%;
            height: 100%;
            background-color: transparent;
            @include max(1200px) {
                font-size: 1.4rem;
            }
            &::placeholder{
                color: $grey-pri;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .textarea{
        width: 100%;
        height: 12rem;
        display: flex;
        padding: 1.2rem 2.4rem;
        background-color: $white;
        textarea{
            width: 100% !important;
            height: 100% !important;
            resize: none;
            font-size: 1.6rem;
            width: 100%;
            height: 100%;
            background-color: transparent;
            &::placeholder{
                color: $grey-pri;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .select2-container{
        width: 100%;
        height: 5rem;
        padding: 0rem 2.4rem;
        background-color: $white;
        @include max(1200px) {
            height: 4rem;
            padding: 0rem 1.2rem;
        }
        .select2-selection__rendered{
            font-size: 1.6rem !important;
            color: $color-text !important;
            font-weight: 400 !important;
            @include max(1200px) {
                font-size: 1.4rem !important;
            }
        }
        .select2-selection--single{
            height: 100% !important;
        }
    }
}

// steps list
.steps-sign{
    margin-bottom: 10rem;
    @include max(1200px) {
        margin-bottom: 5rem;
    }
    @include max(575px) {
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0.5rem;
            height: 0.1rem;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: transparent;
        }    
    }
}
.steps-sign-wr{
    width: calc(10/12*100%);
    margin: 0 auto;
    @include max(1200px) {
        width: 100%;
    }
    @include max(575px) {
        width: 57.5rem
    }
    .steps-list {
        @include row(0.6);
        justify-content: space-between;
        align-items: center;
    }
    .step-item{
        position: relative;
        @include flexCol;
        align-items: center;
        @include item(2,10,0.6);
        @include max(768px) {
            @include item(3,12,0.6)
        }
        &:not(:nth-child(2)){
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                top: 50%;
                right: 0;
                transform: translate(100%, -50%);
                height: 0.2rem;
                background-color: $grey-pri;
                transition: all .3s ease-in-out;
                @include max(768px) {
                    width: calc(6/12*100%)
                }
            }
        }
        &:last-child{
            &::after{
                right: auto;
                left: 0;
                transform: translate(-100%, -50%)
            }
        }
        .step-num{
            @include flexCenter;
            width: 10.6rem;
            height: 10.6rem;
            border-radius: 50%;
            background-color: $grey-sec;
            margin-bottom: 3rem;
            transition: all .3s ease-in-out;
            @include max(1200px) {
                margin-bottom: 2rem;
                width: 8rem;
                height: 8rem;
            }
            @include max(991px) {
                width: 6.8rem;
                height: 6.8rem;
            }
            @include max(768px) {
                width: 5rem;
                height: 5rem;
            }
        }
        .num{
            display: block;
            color: #8C8C8C;
            font-size: 5rem;
            font-weight: 700;
            transition: all .3s ease-in-out;
            @include max(1200px) {
                font-size: 3.8rem;
            }
            @include max(991px) {
                font-size: 3rem;
            }
            @include max(768px) {
                font-size: 2rem;
            }
        }
        .txt{
            color: #8C8C8C;
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            transition: all .3s ease-in-out;
            @include max(1200px) {
                font-size: 1.4rem;
            }
            @include max(768px) {
                font-size: 1.2rem;
            }
        }
        &.active{
            &::after{
                background-color: $red-l;
            }
            // &:not(:last-child){
            //     &::after{
            //         background-color: $red-l;
            //     }
            // }
            // &:nth-child(2) {
            //     &::after{
            //         background-color: $grey-pri;
            //     }
            // }
            .step-num{
                background-color: $red-l;
            }
            .num{
                color: $white;
            }
            .txt{
                color: $color-text;
            }
        }
    }
}


.form-sign-container {
    .form-sign-info-heading{
        background-color: $grey-pri;
    }
    .form-sign-info-heading-wr {
        width: calc(10/12*100%);
        margin: 0 auto;
        padding: 2rem 0;
        @include flexSpc;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 1.2rem;
        @include max(1200px) {
            padding: 2rem 5rem;
        }
        @include max(767px) {
            width: 100%;
            padding: 2rem;
        }
        @include max(575px) {
            padding: 1rem;
        }
        .tt{
            font-size: 2rem;
            font-weight: 700;
            @include max(1200px) {
                font-size: 1.8rem;
            }
            @include max(767px) {
                font-size: 1.6rem;
            }
            @include max(575px) {
                font-size: 1.4rem;
            }
        }
        .btn-more{
            cursor: pointer;
            transition: all .3s ease-in-out;
            @include max(575px) {
                font-size: 1.4rem;
            }
            &:hover{
                color: $color-pri;
            }
        }
    }
    // .form-sign-info-body{
    //     display: none;
    // }
    .form-sign-info-item {
        padding: 6rem 0rem;
        background-color: $grey-sec;
        margin-bottom: 6rem;
        .form-sign-info-item-wr{
            width: calc(10/12*100%);
            margin: 0 auto ;
            @include max(1200px) {
                margin: 0 auto;
            }
            @include max(767px) {
                width: 100%;
            }
        }
        &:last-child{
            margin-bottom: 3rem;
        }
        @include max(1200px) {
            padding: 4rem 0rem;
            margin-bottom: 3rem;
        }
        @include max(767px) {
            padding: 2rem;
        }
        @include max(575px) {
            padding: 1rem;
        }
        .tt-sect{
            color: $color-pri;
            margin-bottom: 4rem;
            text-align: left;
            @include max(1200px) {
                margin-bottom: 2rem;
            }
            @include max(767px) {
                font-size: 2rem;
            }
            @include max(575px) {
                font-size: 1.6rem;
            }
        }
    }

    .txt-accept{
        margin-top: 6rem;
        margin-bottom: 4rem;
        &.hide{
            display: none;
        }
        @include  max(1200px) {
            margin-top: 3rem;
            margin-bottom: 2rem;
        }
        .label{
            input{
                display: none;
                &:checked{
                    ~.box-txt{
                        .box{
                            background-color: $color-text;
                            border: none;
                            .ic{
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
        .box-txt{
            display: flex;
            align-items: center;
            cursor: pointer;
            .box{
                position: relative;
                width: 2rem;
                height: 2rem;
                flex-shrink: 0;
                border: 0.2rem solid rgba(0, 0, 0, 1);
                margin-right: 0.8rem;
                .ic{
                    display: none;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    i{
                        font-size: 1.4rem;
                        color: $white;
                    }
                }
            }
            .txt{
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }
}

.box-search-wr{
    display: flex;
    align-items: center;
    .input{
        display: flex;
        background-color: $grey-sec;
        width: 100%;
        height: 6.8rem;
        padding: 1.2rem 2.4rem;
        input{
            width: 100%;
            height: 100%;
            background-color: transparent;
        }
        .btn-search{
            i{
                font-size: 2rem;
            }
        }
        @include max(1200px) {
            padding: 0.8rem 1.4rem;
            height: 5rem;
        }
    }
}

// item

.item{
    &:hover{
        .thumb{
            &::before{
                animation: shine 0.8s ease-in forwards;
            }
        }
        .content{
            .tt{
                color: $color-second;
            }
        }
    }
    &.blog-item{
        .content{
            display: flex;
            align-items: flex-end;
            border-bottom: 0.1rem solid $color-pri;
            .content-inner{
                padding-right: 1rem;
                @include max(767px) {
                    padding-right: 0;
                }
            }
            .cate{
                font-size: 1.2rem;
                font-weight: 600;
                color: $color-pri;
                margin-bottom: 1rem;
            }
            .tt{
                font-weight: 700;
                letter-spacing: 0.42px;
                text-transform: uppercase;
                margin-bottom: 1rem;
                transition: all .2s ease-in-out;
                @include lc(2);
            }
            .desc{
                color: #595959;
                font-size: 1.2rem;
                @include lc(4);
            }
            .ic{
                @include icon(flex, 2.4, 2.4);
                @include max(1200px) {
                    width: 2rem;
                    height: 2rem;
                }
                @include max(767px) {
                    display: none;
                }
            }
        }
    }
    // .thumb{
    //     @include imgPd(384, 384);
    //     img{
    //         @include imgCover;
    //     }
    // }
    // .content{
    //     .tt{
    //         font-size: 2.4rem;
    //         color: $color-pri;
    //         font-weight: 700;
    //         margin-bottom: 1.2rem;
    //         @include max(1200px) {
    //             font-size: 2rem;
    //         }
    //     }
    //     .txt{
    //         color: #0C0101;
    //         text-align: center;
    //         font-size: 2rem;
    //         letter-spacing: 0.8px;
    //         @include max(1200px) {
    //             font-size: 1.4rem;
    //         }
    //     }
    // }
}

// .box-navi{
//     .btn-navi{
//         &.swiper-button-disabled{
//             background-color: $white;
//             i{
//                 color: $red-pri;
//             }
//         }
//     }
    // &.second{
    //     .btn-navi{
    //         &.swiper-button-disabled{
    //             background-color: #E3E3E3;
    //             i{
    //                 color: #0A0A0A;
    //             }
    //         }
    //     }
    // }
// }



.box-navi{
    width: 100%;
    display: flex;
    .btn-navi{
        width: 5.2rem;
        height: 5.2rem;
        @include flexCenter;
        background-color: $color-pri;
        transition: .4s;
        cursor: pointer;
        @include max(1200px) {
            width: 3rem;
            height: 3rem;
        }
        i{
            color: $white;
            transition: .4s;
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
        &:hover{
            background-color: $color-second;
            transition: .4s;
            i{
                color: rgba(58, 58, 58, 1);
            }
        }
        &.swiper-button-disabled{
            background-color: $color-second;
            i{
                color: rgba(58, 58, 58, 1);
            }
        }
    }
    // &.second{
    //     .btn-navi{
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         width: 4.8rem;
    //         height: 6.4rem;
    //         border-radius: 0.4rem;
    //         background-color: $red-pri;
    //         border: none;
    //         @include max(1200px) {
    //             width: 2.8rem;
    //             height: 4.4rem;
    //         }
    //         i{
    //             font-size: 2.2rem;
    //             color: $white;
    //             @include max(1200px) {
    //                 font-size: 1.4rem;
    //             }
    //         }
    //     }
    // }
}


.content-slider {
    overflow: hidden;
    @include row(1.6);
    justify-content: space-between;
    @include max(1200px) {
        margin: 0 -0.8rem
    }
    .tt-sect{
        margin-bottom: 5rem;
        @include max(1200px) {
            margin-bottom: 2rem
        }
        @include max(700px) {
            text-align: center;
            text-wrap: balance;
            br{
                display: none
            }
        }
    }
    .main-content{
        @include item(6,12,1.6);
        @include max(1200px) {
            padding: 0 0.8rem
        }
        @include max(700px) {
            width: 100%;
            margin-bottom: 3rem;
        }
    }
    .thumb-content{
        @include item(5.3,12,1.6);
        @include max(1200px) {
            padding: 0 0.8rem;
            width: 50%;
        }
        @include max(700px) {
            width: 70%;
            margin: 0 auto;
        }
        @include max(450px) {
            width: 100%;
        }
    }
    .content-item{
        display: flex;
        align-items: center;
        align-items: flex-start;
        margin-bottom: 4rem;
        &:last-child{
            margin-bottom: 0;
        }
        @include max(1200px) {
            margin-bottom: 2rem
        }
        .ic{
            @include icon(flex,4,4);
            margin-right: 2rem;
            @include max(1200px) {
                width: 3rem;
                height: 3rem;
                margin-right: 1rem;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .content{
            p{
                @include max(1200px) {
                    font-size: 1.4rem
                }
            }
        }
    }
    .thumb-content{
        .thumb{
            @include imgPd(606, 520);
            cursor: pointer;
            img{
                @include imgCover();
            }
        }
    }
    .box-navi{
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
    }
}

.banner-decor{
    position: relative;
    background: radial-gradient(66.31% 56.05% at 50% 50%, #A92C25 0%, #280806 100%);
    overflow: hidden;
    &.active{
        position: relative;
        overflow: hidden;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            z-index: 2;
            display: block;
            content: '';
            width: 20%;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
            transform: skewX(-25deg);
            animation: shine 2.2s ease-in forwards;
        }
        .decor-light{
            .decor{
                &.lf{
                    animation: introLightLeft 2.8s ease forwards;
                }
                &.rt{
                    animation: introLightRight 2.8s ease forwards;
                }
            }
        }
    }
    .decor-light{
        position: absolute;
        width: 100%;
        top: 0;
        @include max(700px) {
            height: 100%;
        }
        .decor{
            position: absolute;
            top: 0;
            width: 50%;
            transform-origin: top;
            @include max(700px) {
                height: 80%;
            }
            img{
                width: 100%;
                @include max(700px) {
                    height: 80%;
                }
            }
            &.lf{
                left: 0;
            }
            &.rt{
                right: 0;
            }
        }
    }

    >.decor{
        width: calc(4.5/12*100%);
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateX(100%);
        opacity: 0;
        visibility: visible;
        transition: all 1.2s ease;
        &.active{
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
        }
        img{
            width: 100%;
        }
    }
    .banner-decor-wr{
        position: relative;
        padding: 14.5rem 0;
        @include max(1200px) {
            padding: 10rem 0;
        }
        @include max(767px) {
            padding: 6rem 0;
        }
        .decor.trophy{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: calc(3/12*100%);
            @include max(991px) {
                transform: translate(-30%, -50%);
            }
            @include max(575px) {
                display: none;
            }
            img{
                width: 100%;
                // animation: upDown 2s linear infinite;
            }
            .star{
                position: absolute;
                --size: 10vmin;
                --holes: calc(var(--size)*.495);
                width: var(--size);
                aspect-ratio: 1/1;
                background: white;  
                --mask: radial-gradient(var(--holes) at var(--holes) var(--holes), #0000 99%,#000) calc(var(--holes)*-1) calc(var(--holes)*-1);
                -webkit-mask: var(--mask);
                mask: var(--mask);
                border-radius: 50%;
                animation: sparkle 1s linear forwards;
            }
            &.active{
                img{
                    animation: shakeJump 2.6s ease-in-out forwards;
                }
            }
        }
    }
    .banner-decor-content{
        width: calc(6.2/12*100%);
        margin: 0 auto;
        @include max(991px) {
            width: calc(9/12*100%);
        }
        @include max(575px) {
            width: 100%;
        }
        .tt-sect{
            margin-bottom: 6rem;
            @include max(1200px) {
                margin-bottom: 3rem;
            }
        }
        .content{
            margin-bottom: 4rem;
            color: $white;
            text-align: center;
            @include max(1200px) {
                margin-bottom: 2rem;
            }
        }
        .note{
            display: block;
            margin-bottom: 4rem;
            @include max(1200px) {
                margin-bottom: 2rem;
            }
        }
        .total{
            text-shadow: 0.3rem 0rem $color-second;
            font-size: 6.4rem;
            font-weight: 900;
            font-family: var(--font-four);
            @include max(1200px) {
                font-size: 4.8rem;
            }
            @include max(700px) {
                font-size: 3.8rem;
            }
        }
    }
}

.tag-sect {
    width: fit-content;
    position: relative;
    display: flex;
    margin-bottom: 1.2rem;
    &.pri {
        .box-line{
            .line{
                background-color: $color-pri;
            }
        }
    }
    &.second{
        .box-line{
            .line{
                background-color: $yel-third;
            }
        }
    }
    .txt{
        position: relative;
        display: inline-block;
        padding: 0.4rem 0.8rem;
        color: $white;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: 2.8px;
        &::after{
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            display: block;
            width: 0%;
            height: 100%;
            transition: 0.4s linear;
        }
    }
    .box-line{
        display: flex;
        gap: 0.4rem;
        .line{
            display: block;
            width: 0.3rem;
            height: 0;
            transition: 0.8s ease-in;
        }
        &.lf{
            margin-right: 0.4rem;
        }
        &.rt{
            margin-left: 0.4rem;
        }
    }
    &.active{
        .box-line{
            .line{
                height: 100%;
            }
        }
        &.pri{
            .txt{
                &::after{
                    width: 100%;
                    background-color: $color-pri;
                }
            }
        }
        &.second{
            .txt{
                &::after{
                    width: 100%;
                    background-color: $yel-third;
                }
            }
        }
    }
}

.account-form {
    position: relative;
    z-index: 2;
    background-color: $color-pri;
    // margin: 8rem 0;
    @include max(1200px) {
        padding: 4rem 0;
    }
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .container{
        max-width: 141rem;
    }
    .email-line{
        position: relative;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: #454545;
        font-size: 1.2rem;
        flex: 1;
        margin-top: 4rem;
        @include max(1200px) {
            margin-top: 2rem ;
        }
        &::before, &::after{
            content: '';
            display: block;
            width: 100%;
            height: 0.1rem;
            background-color: #D9D9D9;
            flex: 1;

        }
    }
    .account-form-inner{
        padding: 10rem 0;
        @include max(1200px) {
            padding: 5rem 0;
        }
    }
    .account-form-wr{
        margin: 0;
    }
    .thumb {
        padding: 0;
        @include max(1200px) {
            width: 50%
        }
        @include max(767px) {
            width: 100%;
        }
        .thumb-slider{
            width: 100%;
            height: 100%;
        }
        .img{
            @include imgPd(800, 808);
            height: 100%;
            img{
                @include imgCover;
            }
        }
        .swiper{
            height: 100%;
        }
    }

    .account-form-content{
        padding: 0 !important;
        @include max(1200px) {
            width: 50%;
        }
        @include max(767px) {
            width: 100%;
        }
        .account-form-content-wr{
            @include flexCol;
            justify-content: center;
            background-color: $white;
            padding: 5rem 10rem;
            height: 100%;
            @include max(1200px) {
                padding: 5rem;
            }
            @include max(991px) {
                padding: 2rem;
            }
        }
        .account-form-heading {
            margin-bottom: 4rem;
            @include max(1200px) {
                margin-bottom: 2rem;
            }
            .tt-sect{
                margin-bottom: 1.6rem;
            }
            .login-now{
                color: #454545;
            }
        }
    
        .account-form-group-wr{
            margin-bottom: 3rem;
            @include max(1200px) {
                margin-bottom: 1.6rem;
            }
            .group-form{
                margin-bottom: 3rem;
                &:last-child{
                    margin-bottom: 0;
                }
                @include max(1200px) {
                    margin-bottom: 1.6rem;
                }
                .input{
                    align-items: center;
                    background-color: $grey-sec;
                    input{
                        &::placeholder{
                            color: #BFBFBF;
                        }
                    }
                }
            }
        }
        .btn{
            width: 100%;
        }
        .remember{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4rem;
            @include max(1200px) {
                margin-bottom: 2rem;
            }
            input{
                display: none;
                &:checked{
                    ~.box-txt{
                        .box{
                            background-color: $color-text;
                            border: none;
                            .ic{
                                display: flex;
                            }
                        }
                    }
                }
            }
            .box-txt{
                display: flex;
                cursor: pointer;
                .box{
                    position: relative;
                    width: 2rem;
                    height: 2rem;
                    flex-shrink: 0;
                    border: 0.2rem solid rgba(0, 0, 0, 1);
                    margin-right: 1.5rem;
                    @include max(575px) {
                        width: 1.6rem;
                        height: 1.6rem;
                        margin-right: 0.5rem;
                    }
                    .ic{
                        display: none;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        i{
                            font-size: 1.4rem;
                            color: $white;
                        }
                    }
                }
                .txt{
                    font-size: 1.4rem;
                    font-weight: 500;
                    @include max(1200px) {
                        font-size: 1.4rem;
                    }
                    @include max(575px) {
                        font-size: 1.2rem;
                    }
                }
            }
            .link{
                font-size: 1.4rem;
                @include max(575px) {
                    font-size: 1.2rem;
                }
            }
        }
        .sign-here{
            text-align: center;
            font-size: 1.4rem;
            margin-top: 4rem;
            @include max(1200px) {
                margin-top: 2rem;
            }
            @include max(575px) {
                font-size: 1.2rem;
            }
        }
    }
}


.method-login {
    display: flex;
    align-items: center;
    @include row(0.8);
    margin-top: 4rem;
    @include max(1200px) {
        margin-top: 2rem;
    }
    .method{
        width: 50%;
        padding: 0 0.8rem;
    }
    .method-inner{
        display: flex;
        justify-content: center;
        border: 0.1rem solid #8C8C8C;
        padding: 1.3rem 0;
        .ic{
            @include icon(flex, 2.4, 2.4);
            margin-right: 0.8rem;
            @include max(1200px) {
                width: 2rem;
                height: 2rem;
                margin-right: 0.4rem;
            }
        }
        .txt{
            @include max(1200px) {
                font-size: 1.4rem;
            }
        }
    }
}

.popup-mess{
    position: fixed;
    z-index: 14;
    left: 50%;
    top: 0;
    transform: translate(-50%, -102%);
    background-color: $white;
    width: 30rem;
    border-radius: 1rem;
    box-shadow: 0 0 0.2rem 0.2rem rgba(0,0,0,0.2);
    transition: all .4s ease-out;
    &.open{
        transform: translate(-50%, 10%);
    }
    @include max(575px) {
        width: 25rem;
    }
    .close{
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: block;
        margin-left: auto;
        width: 2rem;
        height: 2rem;
    }
    &.success{
        .inner{
            .ic{
                background-color: #2ecc71;
            }
        }
    }
    &.fail{
        .inner{
            .ic{
                background-color: red;
            }
        }
    }
    .close{
        width: 2rem;
        height: 2rem;
        @include flexCenter;
        cursor: pointer;
        i{
            font-size: 2rem;
            color: $color-text;
        }
    }
    .inner{
        padding: 2.5rem;
        @include flex;
        align-items: center;
        .ic {
            width: 3.5rem;
            height: 3.5rem;
            @include flexCenter;
            clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
            margin-right: 1.2rem;
            flex-shrink: 0;
            i{
                font-size: 1.6rem;
                color: $white;
            }
        }
        .content{
            color: $color-text;
            font-weight: 500;
        }
    }
}

.popup{
    position: fixed;
    z-index: 16;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: 1.6rem;
    transition: all .3s linear;
    opacity: 0;
    visibility: hidden;
    clip-path: inset(0 0 100% 0);
    pointer-events: none;
    &.open{
        opacity: 1;
        visibility: visible;
        clip-path: inset(0 0 0 0);
        pointer-events: visible;
    }
}

.popup-sign {
    @include max(575px) {
        width: 90%;
    }
    .inner{
        padding: 2rem;
    }
    .tt-sect{
        text-align: center;
        margin-bottom: 2rem;
    }
    .btn-sign{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
        @include max(360px) {
            flex-direction: column;
        }
        .btn{
            @include max(360px){
                width: 80%;
            }
            .txt{
                @include max(575px) {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .form-amount{
        margin-top: 2rem;
        display: none;
        &.active{
            display: block;
        }
    }
}


.account-form-group{
    .ic-success{
        position: relative;
        width: 13.3rem;
        height: 13.3rem;
        margin: 0 auto 4rem;
        @include max(1200px) {
            width: 8.5rem;
            height: 8.5rem;
        }
        img{
            width: 100%;
            height: 100%;
        }
        .ic{
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            i{
                font-size: 2.4rem;
                color: $white;
            }
        }
    }
}
.forgot-step{
    margin-bottom: 4rem;
    @include max(1200px) {
        margin-bottom: 2rem;
    }
    @include max(400px) {
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0.5rem;
            height: 0.2rem;
        }
        &::-webkit-scrollbar-track {
            background: $grey-pri;
        }
        &::-webkit-scrollbar-thumb {
            background: $color-pri;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $color-pri;
        } 
    }
    .forgot-step-list{
        display: flex;
        align-items: center;
        @include max(400px) {
            width: 40rem;
        }
        .forgot-step{
            width: calc(4/12*100%);
            &.current{
                .cir{
                    border-color: $color-second;
                }
                .txt{
                    color: $color-second;
                }
            }
            &.done{
                .cir{
                    background-color: $color-second;
                    .ic{
                        display: block;
                    }
                }
            }
            .cir{
                position: relative;
                width: 3rem;
                height: 3rem;
                border: 0.2rem solid $grey-pri;
                border-radius: 50%;
                transition: .3s ease-in-out;
                .ic{
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: none;
                    i{
                        font-weight: 700;
                        color: $white;
                    }
                }
            }
            .txt{
                position: relative;
                color: $grey-pri;
                font-size: 1.2rem;
                font-weight: 500;
                transition: .3s ease-in-out;
                &:not(:last-child) {
                    &::after{
                        content: '';
                        display: block;
                        width: 7rem;
                        height: 0.2rem;
                        background-color: $grey-pri;
                        right: 0;
                        transform: translateX(-100%);
                    }
                }
            }
        }
    }
}

.otp{
    margin-bottom: 4rem;
    @include max(1200px) {
        margin-bottom: 2rem;
    }
    .otp-list {
        @include row(1.5);
        @include max(1200px) {
            margin: 0 -0.8rem;
        }
        .otp-item{
            width: calc(3/12*100%);
            padding: 0 1.5rem;
            @include max(1200px) {
                padding: 0 0.8rem;
            }
            .num{
                @include flexCenter;
                width: 100%;
                height: 100%;
                display: block;
    
                background-color: $grey-pri;
                font-size: 4.8rem;
                font-weight: 700;
    
                text-transform: uppercase;
                text-align: center;
                @include max(1200px) {
                    font-size: 3.8rem;
                }
            }
        }
    }
}

.btn{
    position: relative;
    z-index: 2;
    font-family: var(--font-pri);
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.2rem;
    transition: 0.4s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    @media (max-width:1200px){
        height: 4rem;
    }
    &::before{
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 100%;
        transition: all .3s linear;
    }
    .txt{
        color: $white;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        transition: 0.4s linear;
        @media (max-width:1200px){
            font-size: 1.4rem;
        }
    }
}

.btn.btn-pri{
    background-color: $color-pri;
    padding: 1.6rem 3.2rem;
    @include max(575px) {
        padding: 1rem 2.2rem;
    }
    &::before{
        background-color: $color-second;
    }
    &:hover{
        background-color: $color-second;
        &::before{
            width: 100%;
        }
    }
    &.hidden{
        pointer-events: none;
        &::after{
            content: '';
            position: absolute;
            z-index: 10;
            inset: 0;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.5);
        }
    }
}

.btn.btn-sec{
    background-color: $color-second;
    padding: 1.6rem 3.2rem;
    @include max(575px) {
        padding: 1rem 2.2rem;
    }
    &::before{
        background-color: $color-pri;
    }
    &:hover{
        background-color: $color-pri;
        &::before{
            width: 100%;
        }
    }
    &.hidden{
        pointer-events: none;
        &::after{
            content: '';
            position: absolute;
            z-index: 10;
            inset: 0;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.5);
        }
    }
}

.btn.btn-third{     
    // background-color: $color-pri;
    &:hover{
        // background-color: ;
        .txt{
            // color: ;
        }
    }
}
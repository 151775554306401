.select2{
    width: 100% !important;
}
.select2-container{
    .select2-selection--single{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important;
    }
    .select2-selection__rendered{
        font-size: 2rem !important;
        color: $color-text !important;
        font-weight: 700 !important;
        padding: 0 !important;
    }
    .select2-selection__arrow{
        background-image: url(/template/assets/images/arr-down.svg);
        background-repeat: no-repeat;
        background-size: 2rem;
        transition: .4s;
        position: static !important;
        width: 2rem !important;
        height: 2rem !important;
        display: block !important;
        >b{
            display: none;
        }
    }
}
.select2-container--open{
    .select2-selection__arrow{
        transform: rotate(180deg);
    }
}

.select2-results__option--selectable {
    transition: .3s linear;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $color-pri;
}

.select2-container {
    .select2-search {
        display: none;
    }
    .select2-dropdown{
        border: none;
    }
}
@import url("../assets/font/Montserrat/stylesheet.css");
@import url("../assets/font/Inter/stylesheet.css");
@import url("../assets/font/Oswald/stylesheet.css");
@import url("../assets/font/Iowan-old-style/stylesheet.css");

* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: var(--font-pri);
    font-size: 16px;
    line-height: 1.55;
    font-weight: 400;
    color: $color-text;
    overflow: hidden auto;

    @media screen and (min-width: 2000px) {
        font-size: 20px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;

}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    font-family: var(--font-pri);
    font-size: 1.6rem;
    color: $black;
    &::placeholder{
        font-size: 1.6rem;
    }
}

.main {
    min-height: 100vh;
}

.container {
    width: 100%;
    max-width: 123rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

.tabPanel{
    display: none;
}

iframe {
    vertical-align: middle;
}

img {
    max-width: 100%;
    height: auto;
}

.overlay{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: rgba(0,0,0,0.5);
    display: none;
}

.overlay.active{
    display: block;
}

.overlay-full{
    position: fixed;
    z-index: 15;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: none;
}

.overlay-full.active{
    display: block;
}

.d-flex{
    display: flex;
}

.d-flex-col{
    display: flex;
    flex-direction: column;
}

.p-center{
    margin: 0 auto;
}

.p-lf{
    margin-left: auto;
}

.p-rt{
    margin-right: auto;
}

.ss-pd{
    padding: calc(var(--ss-pd)/2) 0;
}

.ss-pd-t{
    padding-top: calc(var(--ss-pd)*2);
}

.ss-pd-b{
    padding-bottom: calc(var(--ss-pd)*2);
}

.aside{
    @media (min-width:800px) {
        position: sticky;
        top: calc(var(--size-hd) + 2rem);
        height: 100%;
        overflow: hidden;
    }
    @media (max-width:800px) {
        position: fixed;
        z-index: 13;
        top: 50%;
        // top: var(--size-hd);
        left: 0;
        bottom: 0;
        width: 30rem;
        transform: translate(-100%, -50%);
        // transform: translate(-100%, 0);
        overflow: visible;
        padding: 0;
        transition: all .4s ease-out;
    }
    .aside-wr{
        overflow: auto;
    }
    &.aside-img{
        @media (max-width:800px){
            position: unset;
            z-index: 0;
            width: 100%;
            height: 100%;
            transform: translate(0,0);
        }
    }
    .bar{
        @media (min-width:800px){
            display: none;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 3rem;
        height: 2.5rem;
        position: absolute;
        top: 50%;
        transform: translate(100%, -50%);
        right: 0;
        background-color: $color-pri;
        padding: 0.5rem;
        box-shadow: 0 0 2rem 0.5rem rgba(0,0,0,0.2);
        .line{
            display: block;
            width: 100%;
            height: 0.2rem;
            background-color: $white;
        }
        &.second{
            justify-content: center;
            width: 4rem;
            height: 4rem;
            border-radius: 0 4rem 4rem 0;
            i{
                color: $white;
            }
        }
    }
    &.open{
        transform: translate(0, -50%);
    }
        
}

.load-item, .collapseContent{
    display: none;
}

.woocommerce form .form-row label{
    line-height: 100%;
}



.lg-thumb-outer.lg-grab {
    display: none;
}

.lg-download, .lg-autoplay-button, .lg-fullscreen, #lg-zoom-in, #lg-zoom-out
, #lg-actual-size, #lg-share, .lg-flip-ver, .lg-flip-hor, .lg-rotate-left, .lg-rotate-right{
    display: none;
}

.d-flex-rev{
    display: flex;
    flex-direction: row-reverse;
}



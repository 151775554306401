.paginations{
    margin-top: 6rem;
    @include max(1200px) {
        margin-top: 3rem;
    }
    .page-numbers{
        display: flex;
        justify-content: center;
        align-items: center;
        @include max(700px) {
            justify-content: center;
        }
        li{
            margin-right: 1.2rem;
            &:last-child{
                margin-right: 0;
            }
            >.prev.page-numbers, > .next.page-numbers{
                background-color: $color-pri;
                i{
                    font-weight: 400;
                    @include max(767px) {
                        font-size: 1.4rem;
                    }
                }
            }
            >.page-numbers.current{
                display: flex;
                padding: 0.5rem;
                background: $color-second;
                color: $color-text;
                transform: 0.4s;
            }
            >.page-numbers{
                display: flex;
                justify-content: center;
                color: $color-text;
                height: 5.2rem;
                min-width: 5.2rem;
                font-size: 2rem;
                font-weight: 600;
                transition: 0.4s;
                background-color: $grey-pri;
                color: $white;
                @include max(768px) {
                    height: 4.2rem;
                    min-width: 4.2rem;
                    font-size: 1.6rem;
                }
                @include max(575px) {
                    height: 3.6rem;
                    min-width: 3.6rem;
                    font-size: 1.4rem;
                }
                @include max(320px) {
                    height: 2.8rem;
                    min-width: 2.8rem;
                    font-size: 1.4rem;
                }
            }
            &:hover{
                >.page-numbers{
                    background: $color-second;
                    color: $white;
                }
            }
        }
    }
}
.overviews{
    position: relative;
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        height: 140%;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.event {
    .tt-sect{
        margin-bottom: 8rem;
        @include max(1200px) {
            margin-bottom: 4rem
        }
    }
    .event-list{
        @include row(1.6);
        row-gap: 3.2rem;
        @include max(1200px) {
            margin: 0 -0.8rem;
            row-gap: 1.6rem;
        }
    }
    .item{
        width: calc(4/12*100%);
        padding: 0 1.6rem;
        @include max(1200px) {
            padding: 0 0.8rem;
        }
        @include max(700px) {
            width: 50%;
        }
        &:hover{
            .content{
                .tt{
                    color: $color-second
                }
            }
        }
        .thumb{
            @include imgPd(384, 384);
            img{
                @include imgCover;
            }
        }
        .content{
            padding: 2rem;
            text-align: center;
            @include max(1200px) {
                padding: 0.8rem;
            }
            .tt{
                font-size: 2.4rem;
                font-weight: 700;
                color: $color-pri;
                margin-bottom: 1.2rem;
                transition: all .2s ease-in-out;
                @include max(1200px) {
                    font-size: 2rem;
                }
                @include max(800px) {
                    font-size: 1.6rem;
                    margin-bottom: 0;
                }
                @include max(500px) {
                    font-size: 1.4rem;
                }
            }
            .txt{
                color: #0C0101;
                font-size: 2rem;
                @include max(1200px) {
                    font-size: 1.6rem;
                }
                @include max(800px) {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.prize-wr{
    .tt-sect{
        margin-bottom: 6rem;
        @include max(1200px) {
            margin-bottom: 3rem;
        }
    }
}

.prize-heading {
    margin-bottom: 6rem;
    @include max(1200px) {
        margin-bottom: 3rem;
    }
    @include max(700px) {
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0.5rem;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: transparent;
        }        
    }
    .prize-tab {
        background-color: #F0F0F0;
        padding: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4rem;
        @include min(700px) {
            overflow: auto;
            &::-webkit-scrollbar {
                width: 0.5rem;
                height: 1rem;
            }
            &::-webkit-scrollbar-track {
                background: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background: $color-pri;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: $color-pri;
            }    
        }
        @include max(1200px) {
            // gap: 2rem;
            gap: 0;
            padding: 1rem;
        }
        @include max(700px) {
            width: 70rem;
        }
    }
    .prize-tab-btn{
        padding: 2rem;
        cursor: pointer;
        @include max(1200px) {
            padding: 1rem;
        }
        &.active{
            background-color: $white;
            .txt{
                color: $color-pri;
            }
        }
        .txt{
            color: #8C8C8C;
            font-size: 2rem;
            font-weight: 700;
            text-wrap: nowrap;
            transition: all 0.3s ease-in-out;
            @include max(1200px) {
                font-size: 1.6rem;
                // text-wrap: wrap
            }
            @include max(800px) {
                font-size: 1.4rem
            }
        }
    }
}

.prize-content-row {
    .thumb{
        .img{
            @include imgPd(471, 624);
            height: 100%;
            img{
                @include imgCover;
            }
        }
        @include max(650px) {
            width: 100%;
        }
    }
    .content{
        padding-left: 7.2rem;
        @include max(1200px) {
            padding-left: 3rem;
        }
        @include max(767px) {
            padding-left: 0.8rem;
        }
        @include max(650px) {
            width: 100%;
        }
    }
    .p-prize{
        margin-bottom: 5rem;
        @include max(1200px) {
            margin-bottom: 2rem;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .heading{
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            .ic{
                @include icon(flex, 4, 4);
                margin-right: 1rem;
                @include max(1200px) {
                    width: 3rem;
                    height: 3rem;
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .content{
            color: #1F1F1F;
            padding-left: 0;
            p{
                @include max(1024px) {
                    font-size: 1.4rem;
                }
            }
            ul{
                li{
                    display: flex;
                    align-items: center;
                    @include max(1024px) {
                        font-size: 1.4rem;
                    }
                    &::before{
                        content: '';
                        display: block;
                        width: 0.4rem;
                        height: 0.4rem;
                        margin-right: 0.5rem;
                        border-radius: 50%;
                        background-color: #0C0101;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}

.proce{
    position: relative;
    .bg{
        position: absolute;
        z-index: -1;
        inset: 0;
        transform: translateY(40%);
        img{
            width: 100%;
            height: 100%;
        }
    }
    .tt-sect{
        margin-bottom: 8rem;
        @include max(1200px) {
            margin-bottom: 4rem;
        }
    }
    .proce-list-container {
        @include max(700px) {
            overflow: auto;
            &::-webkit-scrollbar {
                width: 0.5rem;
                height: 0.2rem;
            }
            &::-webkit-scrollbar-track {
                background: $grey-pri;
            }
            &::-webkit-scrollbar-thumb {
                background: $color-pri;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: $color-pri;
            }   
        }
    }

    .proce-list{
        @include row(1.6);
        @include max(1200px) {
            margin: 0 -0.8rem;
        }
        @include max(700px) {
            width: 70rem
        }
        .proce-item{
            @include item(3,12,1.6);
            @include flexCol;
            @include max(1200px) {
                padding: 0.8rem;
            }
        }
    }
    .proce-item-heading{
        margin-bottom: 6rem;
        text-align: center;
        background-color: $grey-sec;
        @include max(1200px) {
            margin-bottom: 3rem;
        }
        .proce-item-heading-inner{
            padding: 2rem;
            @include flexCol;
            justify-content: flex-start;
            min-height: 27rem;
            @include max(1200px) {
                padding: 1rem;
            }
            @include max(700px) {
                min-height: 16rem;
            }
        }
        .num{
            color: $color-pri;
            font-size: 9.6rem;
            font-weight: 800;
            @include max(1200px) {
                font-size: 5rem;
            }
            @include max(991px) {
                font-size: 3.8rem;
            }
        }
        .txt{
            font-size: 2.4rem;
            font-weight: 500;
            @include max(1200px) {
                font-size: 1.6rem;
            }
            @include max(991px) {
                font-size: 1.4rem;
            }
        }
    }
    .proce-item-content{
        // .proce-item-content-inner{
        //     padding: 0 5rem;
        //     @include max(1200px) {
        //         padding: 0 2.5rem;
        //     }
        //     @include max(700px) {
        //         padding: 0 1.4rem;
        //     }
        // }
        .thumb-box{
            margin-bottom: 6rem;
            text-align: center;
            &:last-child{
                margin-bottom: 0;
            }
            @include max(1200px) {
                margin-bottom: 3rem;
            }
            .img{
                width: 65%;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }
            .txt{
                font-size: 2.4rem;
                font-weight: 500;
                text-align: center;
                @include max(1200px) {
                    font-size: 1.6rem;
                }
                @include max(991px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

.hand-book{
    overflow: hidden;
    .tt-sect{
        margin-bottom: 8rem;
        @include max(1200px) {
            margin-bottom: 4rem;
        }
    }
    .hand-book-slider{
        .swiper{
            margin: 0 -3rem;
            @include max(1200px) {
                margin: 0 -1.5rem;
            }
            @include max(575px) {
                margin: -0.8rem;
            }
        }
        .swiper-slide{
            width: calc(4/12*100%) !important;
            padding: 3rem;
            @include max(1200px) {
                padding: 0 1.5rem;
            }
            @include max(575px) {
                width: calc(5/12*100%) !important;
                padding: 0 0.8rem;
            }
            @include max(399px) {
                width: 50% !important;
            }
        }
        .swiper-pagination{
            margin-top: 8rem;
            @include max(1200px) {
                margin-top: 4rem
            }
        }
        .thumb{
            @include imgPd(637, 478);
            img{
                @include imgCover;
            }
        }
    }
}

.rules{
    .tt-sect.pri{
        margin-bottom: 8rem;
        @include max (1200px) {
            margin-bottom: 4rem;
        }
    }
}
.rules-item{
    &.active{
        .rules-heading{
            .ic{
                .line{
                    &:last-child{
                        transform: translate(-50%, 0) rotate(90deg);
                    }
                }
            }
        }
    }
    .rules-heading{
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
        cursor: pointer;
        .ic{
            position: relative;
            width: 2.2rem;
            height: 2.2rem;
            margin-right: 0.8rem;
            @include max(650px){
                width: 1.6rem;
                height: 1.6rem;
            }
            .line{
                display: block;
                position: absolute;
                background-color: $color-pri;
                transition: all .3s ease-in-out;
                &:first-child{
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 100%;
                    height: 0.5rem;
                    @include max(650px) {
                        height: 0.35rem;
                    }
                }
                &:last-child{
                    width: 0.5rem;
                    height: 100%;
                    left: 50%;
                    transform: translate(-50%, 0);
                    @include max(650px) {
                        width: 0.35rem;
                    }
                }
            }
        }
        .tt-sect{
            text-wrap: pretty;
            @include max(650px) {
                font-size: 1.6rem;
            }
        }
    }
    .rules-content{
        .rules-content-item{
            margin-bottom: 3.6rem;
            p{
                @include max(650px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
.swiper-pagination {
    bottom: 2.4rem;
    .swiper-pagination-bullet{
        width: 0.8rem;
        height: 0.8rem;
        background-color: $white;
        opacity: 1;
        border-radius: 0;
        transition: all .4s ease-out;
        margin-left: 0;
        margin-right: 1.2rem;
        &:last-child{
            margin-right: 0;
        }
        &.swiper-pagination-bullet-active{
            background: linear-gradient(0deg, #C41010 0%, #DD3F45 100%);
            transform: rotate(45deg) scale(1.1);
        }
    }
    &.second{
        position: static;
        .swiper-pagination-bullet{
            background-color: $color-text;
            opacity: 1;
            margin-left: 0;
            margin-right: 1.2rem;
            &.swiper-pagination-bullet-active{
                background: linear-gradient(0deg, #C41010 0%, #DD3F45 100%);
                transform: rotate(45deg) scale(1.1);
            }
        }
        
    }
}
:root {
    --font-pri: 'Montserrat', serif;
    --font-sec: 'Inter', serif;
    --font-third: 'Oswald', serif;
    --font-four: 'IowanOldSt Blk BT', serif;
    --w-logo: 6rem;
    --w-logo-ft: 5.4rem;
    --hd-top: 3.6rem;
    --hd-bot: 8.6rem;
    --size-hd: calc(3.6rem + 8.6rem);
    --ss-pd: 8rem;
    --ss-pd-t: 16rem;
    --ss-pd-b: 16rem;
    --it-pd: calc(3.2rem / 2);
    --w-hd: calc(100vw - (100vw - 120rem) /2);
    --w-container: calc(100vw - (100vw - 120rem) /2);
    --pd-acc: 10rem;
    @media (max-width:1300px) {
        --w-hd: 100%;
        --pd-acc: 5rem;
    }
    @media (max-width:1200px) {
        --w-container:100%;
        --size-hd: calc(3.6rem + 6rem);
        --container-hd: 100%;
        --hd-bot: 6rem;
        --w-logo: 4.5rem;
        --ss-pd: 4rem;
        --ss-pd-t: 8rem;
        --ss-pd-b: 8rem;
        --it-pd: 0.8rem;
    }
    @media (max-width:575px) {
        --pd-acc: 2rem;

    }
    @media (max-width:400px) {
        --w-logo: 2.5rem;
    }
}

// color
$color-pri: #A81419;
$color-second: #F4C53A;
$yel: #FFCC37;
$yel-sec: #FFD001;
$yel-third: #FFB801;
$color-third: '';
$color-text: #3A3A3A;
$red-l : #C6171D;
$red-opa: #DC6A6A;
$red-wine: #590A0D;
$grey-pri: #BFBFBF;
$grey-sec: #F5F5F5;
$grey-third: #DCDCDC;
$black: #000000;
$black-sec: #202020;
$white: #ffffff;

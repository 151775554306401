// form sign detail heading 
.form-container{
    margin-top: 10rem;
    @include max(1200px) {
        margin-top: 5rem;
    }
    .form-heading {
        margin-bottom: 6rem;
        @include max(1200px) {
            margin-bottom: 3rem;
        }
        .tt-sect{
            color: $color-pri;
            text-align: center;
        }
    }
    
    .form-grid{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: auto;
        gap: 3.2rem;
        @include max(1200px) {
            gap: 1.6rem;
        }
        @include max(767px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.form-pay{
    &:first-child{
        grid-column: 1/3;
        grid-row: 1/2;
        @include max(767px) {
            width: 80%;
            margin: 0 auto;
        }
        @include max(575px) {
            width: 100%;
            .btn{
                margin: 0 auto;
            }
        }
    }
    &:nth-child(2){
        grid-column: 3/5;
        grid-row: 1/3;
        .btn{
            width: 100%;
        }
        @include max(767px) {
            width: 80%;
            margin: 0 auto;
            order: 3;
        }
        @include max(575px) {
            width: 100%;
            .btn{
                margin: 0 auto;
            }
        }
    }
    &:nth-child(3){
        grid-column: 1/3;
        grid-row: 2/3;
        @include max(767px) {
            width: 80%;
            margin: 0 auto;
        }
        @include max(575px) {
            width: 100%;
            .btn{
                margin: 0 auto;
            }
        }
    }
    .form-pay-inner{
        padding: 4rem;
        background-color: $grey-sec;
        @include max(1200px) {
            padding: 2rem;
        }
        @include max(991px) {
            padding: 1.4rem;
        }
    }
    .form-pay-heading{
        margin-bottom: 2rem
    }
}

.info-pay-list{
    @include flexCol;
    row-gap: 2rem;

    padding: 2rem 0;
    border-top: 0.1rem solid $grey-pri;
    .info-pay-item{
        @include flexSpc;
        align-items: center;
        margin: 0 -0.6rem;
        .label-info{
            width: 50%;
            padding: 0 0.6rem;
            font-weight: 500;
            @include max(1200px) {
                width: 40%;
                font-size: 1.4rem;
            }
        }
        .txt-info{
            width: 50%;
            padding: 0 0.6rem;
            font-weight: 600;
            word-break: break-all;
            @include max(1200px) {
                width: 60%;
                font-size: 1.4rem;
            }
        }
        &.total{
            .label-info{
                font-weight: 600;
            }
            .txt-info{
                font-weight: 700;
                color: $color-pri;
            }
        }
    }
}
.method-pay{
    padding-top: 2rem;
    border-top: 0.1rem solid $grey-pri;
}

.filter-pay{
    padding: 2rem 0;
    .filter-wr{
        .label{
            input{
                &:checked{
                    ~.box-txt{
                        .box{
                            background-color: $red-l;
                            border: none;
                            &::before{
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
        .box-txt{
            .box{
                position: relative;
                border-radius: 50%;
                &::before{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 0.8rem;
                    height: 0.8rem;
                    border-radius: 50%;
                    background-color: $white;
                    display: none;
                }
            }
        }
    }
}

.filter-rules{
    padding: 2rem 0;
    border-top: 0.1rem solid $grey-pri;
    .filter-wr{
        .label{
            input{
                &:checked{
                    ~.box-txt{
                        .box{
                            background-color: $color-text;
                            border: none;
                            .ic{
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
        .box-txt{
            .box{
                position: relative;
                .ic{
                    display: none;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    i{
                        font-size: 1.4rem;
                        color: $white;
                    }
                }
            }
            .txt{
                font-size: 1.4rem;
            }
        }
    }
}

.filter-wr{
    .label{
        display: block;
        margin-bottom: 2rem;
        &:last-child{
            margin-bottom: 0;
        }
        input{
            display: none;
        }
        .box-txt{
            display: flex;
            cursor: pointer;
            .box{
                width: 2rem;
                height: 2rem;
                flex-shrink: 0;
                border: 0.2rem solid rgba(0, 0, 0, 1);
                margin-right: 1.5rem;
            }
            .txt{
                font-weight: 500;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
.album-sect-wr{
    .album-txt {
        font-weight: 700;
        margin-bottom: 6rem;
        @include max(1200px) {
            margin-bottom: 3rem
        }
    }
    .result {
        margin-bottom: 6rem;
        @include max(1200px) {
            margin-bottom: 3rem
        }
    }
}
.album-search{
    @include row(1.6);
    margin-bottom: 6rem;
    @include max(1200px) {
        margin: 0 -0.8rem 3rem;
    }
    @include max(575px) {
        row-gap: 1.4rem;
    }
    .album-search-select {
        width: 50%;
        padding: 0 1.6rem;
        @include max(1200px) {
            padding: 0 0.8rem;
        }
        @include max(575px) {
            width: 100%;
        }
        .select2-container .select2-selection--single{
            height: 6.8rem;
            padding: 2rem 3rem;
            background-color: $grey-sec !important;
            @include max(1200px) {
                height: 5rem;
                padding: 1rem 1.5rem;
                background-color: $grey-sec !important;
            }
        }
        .select2-container .select2-selection__rendered{
            @include max(1200px) {
                font-size: 1.4rem !important;
            }
        }
    }
    .box-search{
        width: 50%;
        padding: 0 1,6rem;
        @include max(1200px) {
            padding: 0 0.8rem;
        }
        @include max(575px) {
            width: 100%;
        }
    }
}

.album-item{
    @include max(1200px) {
        padding: 0 0.8rem;
    }
    @include max(767px) {
        width: calc(4/12*100%);
    }
    @include max(575px) {
        width: calc(6/12*100%);
    }
    &:hover{
        .thumb{
            cursor: pointer;
            img{
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }
    .thumb{
        @include imgPd(210, 280);
        img{
            @include imgCover;
            transition: 0.2s ease-in-out;
        }
    }
}
// Library
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/gallery/lightgallery.min.css");
// @import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/datetime/daterangepicker.css");
// @import url("../assets/library/magnify/magnify.css");

// Core
@import "./core/reset";
@import "./core/mona";
@import "./core/variable";
@import "./core/font";
@import "./core/base";
@import "./core/mixin";

// Component
@import "./components/header";
@import "./components/footer";
@import "./components/components";

// Layout
@import "./layouts/btn";
@import "./layouts/flex";
@import "./layouts/color";
@import "./layouts/keyframe";
@import "./layouts/hambuger";
@import "./layouts/amount";
@import "./layouts/breadcrumbs";
@import "./layouts/scrollBar";
@import "./layouts/social";
@import "./layouts/stars";
@import "./layouts/text";
@import "./layouts/page-numbers";
@import "./layouts/range-slider";
@import "./layouts/swiper";
@import "./layouts/select2";
@import "./layouts/daterangepicker";

// Pages
@import "./pages/home";
@import "./pages/thank-you";
@import "./pages/pay";
@import "./pages/sign-detail";
@import "./pages/album";
@import "./pages/account";
@import "./pages/sign";
@import "./pages/contact";
@import "./pages/blog-detail";
@import "./pages/blog";
@import "./pages/partner";
@import "./pages/tournaments";
@import "./pages/sign-second";
@import "./pages/forgot";
@import "./pages/cart";
// @import "./pages/";
.acc-sect-container{
    width: var(--w-container);
    margin-left: auto;
    // overflow: hidden;
    @include max(1200px) {
        padding: 0 1.5rem;
        margin: 0 auto;
    }
}.acc-sect-wr{

}
.side-acc{
    position: sticky;
    top: calc(var(--size-hd) + 2rem);
    height: 100%;
    overflow: hidden;
    @include max(1200px) {
        width: calc(4/12*100%);
        padding: 0 0.8rem;
    }
    @include max(650px) {
        width: 100%;
        position: unset;
        overflow: visible;
        // padding-top: 3rem;
    }
    .side-acc-wr{
        padding: var(--pd-acc) 0;
    }
}

.acc-main{
    @include max(1200px) {
        width: calc(8/12*100%);
        padding: 0 0.8rem;
    }
    @include max(650px) {
        width: 100%;
    }
    .success-wr{
        margin-bottom: 3rem;
        .noti{
            font-weight: 500;
        }
        .checkout-done-box{
            margin: 2rem 0;
        }
        .info-table-item{
            tr{
                border: 0.1rem solid $color-text;
                &:not(:last-child) {
                    border-bottom: none;
                }
            }
        }
    }
}
.side-acc-hd{
    // padding-top: 10rem;
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    @include max(1200px) {
        margin-bottom: 2rem;
    }
    .ava{
        width: 7.2rem;
        height: 7.2rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 3.2rem;
        @include max(1200px) {
            width: 5.2rem;
            height: 5.2rem;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .acc-txt{
        @include max(1200px) {
            font-size: 1.4rem;
        }
    }
}

.side-acc-bd {
    .menu-item{
        margin-bottom: 3rem;
        padding-left: 1.8rem;
        @include max(1200px) {
            margin-bottom: 1.2rem;
            padding-left: 1rem;
        }
        &:last-child{
            margin-bottom: 0;
        }
        &.active{
            .menu-link{
                color: $color-pri;
                .ic{
                    img{
                        filter: brightness(0) saturate(100%) invert(8%) sepia(85%) saturate(7013%) hue-rotate(354deg) brightness(88%) contrast(89%);
                    }
                }
            }
        }
    }
    .menu-link{
        display: flex;
        align-items: center;
        
        font-size: 1.8rem;
        font-weight: 600;
        transition: all .3s ease-in-out;
        @include max(1200px) {
            font-size: 1.4rem;
        }
        &:hover{
            color: $color-pri;
            .ic{
                img{
                    filter: brightness(0) saturate(100%) invert(8%) sepia(85%) saturate(7013%) hue-rotate(354deg) brightness(88%) contrast(89%);
                }
            }
        }
        .ic{
            @include icon(flex, 3.2, 3.2);
            margin-right: 5rem;
            @include max(1200px) {
                margin-right: 2.5rem;
                width: 2.2rem;
                height: 2.2rem;
            }
            img{
                transition: all .3s ease-in-out;         
            }
        }
        .nofi {
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 50%;
            background-color: $color-second;
            @include flexCenter;
            margin-left: 0.8rem;
            .num{
                color: $black;
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
    }
}

.acc-main{
}

.form-acc {
    background-color: #F0F0F0;
    padding: var(--pd-acc) 1.6rem;
    .form-acc-wr{
        margin-left: 20rem;
        width: calc(4/8*100%);
        @include max(1200px) {
            margin-left: 0;
            margin: 0 auto;
            width: calc(6/8*100%);
        }
        @include max(650px) {
            width: 100%;
        }
    }

    input[type=file]{
        display: none;
    }
    .ava-label{
        position: relative;
        z-index: 1;
        display: block;
        width: 18.9rem;
        height: 18.9rem;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 auto 6rem;
        @include max(1200px) {
            margin: 0 auto 3rem;
            width: 16.5rem;
            height: 16.5rem;
        }
        .loading-img{
            width: 18.9rem;
            height: 18.9rem;
            @include max(1200px) {
                width: 16.5rem;
                height: 16.5rem;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .preview-img{
            position: absolute;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            background-color: $white;
            z-index: 1;
            bottom: 0;
            right: 0;
            @include flexCenter;
            .button-upload{
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }
    .group-form{
        margin-bottom: 3rem;
        @include max(1200px) {
            margin-bottom: 1.8rem;
        }
    }
    .btn{
        width: 100%;
    }
}
.form-orders{
    background-color: #F0F0F0;
    padding:  var(--pd-acc) 1.6rem;
    .form-orders-wr{
        width: calc(7/8*100%);
        padding-left: 7rem;
        padding-right: 25.7rem;
        background-color: #F0F0F0;
        @include max(1200px) {
            padding: 0;
            width: 100%;
        }
        .form-orders-search{
            margin-bottom: 4rem;
            @include max(1200px) {
                margin-bottom: 2rem;
            }
            .input{
                display: flex;
                align-items: center;
                height: 6rem;
                background-color: #D9D9D9;
                padding: 1rem 3.2rem;
                @include max(1200px) {
                    padding: 1.2rem;
                    height: 4.2rem;
                }
                input{
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    &::placeholder{
                        @include max(1200px) {
                            font-size: 1.4rem;
                        }
                    }
                }
                .btn-search{
                    margin-right: 1.4rem;
                    i{
                        font-size: 2rem;
                        color: #8C8C8C;
                        @include max(1200px) {
                            font-size: 1.6rem;
                        }
                    }
                }
                input{
                    &::placeholder{
                        color: #8C8C8C;
                    }
                }
            }
        }
    }
}

.form-orders-result{
    .form-orders-result-box{
        margin-bottom: 4rem;
        .inner{
            padding: 2.4rem 4.8rem;
            background-color: $white;
            @include max(1200px) {
                padding: 2rem;
            }
            .tt{
                color: $red-l;
                margin-bottom: 1.2rem;
                @include max(1200px) {
                    font-size: 1.4rem;
                }
            }
        }
        .code{
            display: block;
            color: $grey-pri;
            font-size: 1.4rem;
            font-weight: 600;
            margin-bottom: 1.2rem;
            @include max(1200px) {
                font-size: 1.2rem;
            }
        }
        .info-table{
            margin-bottom: 1.6rem;
            .info-table-item{
                display: flex;
                align-items: center;
                margin-bottom: 2rem;
                &:last-child{
                    margin-bottom: 0;
                }
                .label{
                    width: 40%;
                    font-weight: 500;
                    @include max(1200px) {
                        font-size: 1.4rem;
                        padding: 1.4rem;
                    }
                }
                .txt{
                    width: 60%;
                    font-weight: 600;
                    @include max(1200px) {
                        font-size: 1.4rem;
                        padding: 1.4rem;
                    }
                }
            }
        }
        .btn{
            display: flex;
            margin-left: auto;
        }
    }
}

.form-nofi{
    background-color: $white;
    padding:  var(--pd-acc) 1.6rem;
    @include max(1200px) {
        padding:  var(--pd-acc) 0rem;
    }
    .form-noti-wr{
        width: calc(8/12*100%);
        @include max(1200px) {
            width: 100%;
        }
    }
    .box-nofi{
        margin-bottom: 3rem;
        &:last-child{
            margin-bottom: 0;
        }
        .box-nofi-inner{
            background-color: #f5f5f5;
            padding: 2.4rem 4.8rem;
            @include max(1200px) {
                padding: 1.2rem 2rem;
            }
        }
        .date{
            display: block;
            color: $grey-pri;
            font-size: 1.4rem;
            font-style: 600;
            margin-bottom: 1.2rem;
        }
        .tt{
            color: $red-l;
            font-weight: 600;
            margin-bottom: 1.2rem;
        }
        .desc{
            color: #595959;

        }
    }
}

.woocommerce-order-details{
    padding: 5rem 1.6rem;
    @include max(1200px) {
        padding: 5rem 0.8rem;
    }
    @include max(575px) {
        padding: 2rem 0.8rem;
    }
    .woocommerce-order-details__title{
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2.4rem;
        text-transform: uppercase;
    }
    .info-table{
        width: 100%;
        margin-bottom: 2.4rem;
        .info-table-item{
            tr{
                display: flex;
                // margin-bottom: 0.8rem;
                border: 0.1rem solid $color-text;
                &:not(:last-child) {
                    border-bottom: none;
                }
            }
            .label{
                position: relative;
                font-weight: 700;
                width: 25rem;
                text-align: left;
                padding: 2rem;
                @include max(1200px) {
                    width: 18rem;
                    font-size: 1.4rem;
                    padding: 1.4rem;
                }
                @include max(575px) {
                    width: 12rem;
                }
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 0.1rem;
                    height: 100%;
                    background-color: $color-text;
                }
            }
            .txt{
                font-weight: 500;
                flex: 1;
                text-align: left;
                padding: 2rem;
                @include max(1200px) {
                    font-size: 1.4rem;
                    padding: 1.4rem;
                }
                @include max(575px) {
                    text-wrap: wrap;
                    word-break: break-all;
                }
                .text-name, .txt-price{
                    color: $color-pri;
                }
            }
        }
    }
    .woocommerce-table--order-details{
        thead{
            .product-name {
                background-color: $red-l;
            }
    
            .product-total{
                background-color: $red-l;
            }
        }

        tbody{
            .product-name{
                font-weight: 700;
            }

            .product-total{
                font-weight: 500;
            }
        }

        tfoot{
            th{
                background-color: $red-l;
            }
            td{
                font-weight: 500 !important;
            }
        }
    }
    .woocommerce-customer-details{
        .woocommerce-columns--addresses{
            margin: 0 -1.6rem;
            .woocommerce-column{
                width: 50%;
                .woocommerce-column__title{
                    font-size: 2rem;
                    font-weight: 600;
                    margin-bottom: 1.4rem;
                }
                address{
                    font-weight: 500;
                }
            }
        }
    }
    .form-sign-info-item{
        margin-top: 4rem;
        background-color: $grey-sec;
        .form-sign-info-item-wr{
            padding: 2rem;
        }
        .tt-sect{
            margin-bottom: 2.4rem;
        }
        .form-group-list{
            margin: 0;
        }
    }
    .created-posts-content{
        h3 {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 2.4rem;
            text-transform: uppercase;
        }
        .created-posts-lists{
            @include flexCol;
            row-gap: 1.6rem;
        }
        .created-posts-lists-item{
            @include flexCol;
            > a{
                color: $color-pri;
                font-weight: 700;
            }
            .created-posts-lists-item-link{
                position: relative;
                display: flex;
                gap: 2rem;
                &::after{
                    content: 'Đã sao chép !';
                    font-size: 1.4rem;
                    position: absolute;
                    right: 20rem;
                    color: $color-pri;
                    font-weight: 600;
                    transform: translateY(3rem);
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: all .4s ease-in-out;
                }
                @include min(1024px) {
                    &.active{
                        &::after{
                            transform: translateY(-3rem);
                            opacity: 1;
                            visibility: visible;
                            pointer-events: visible;
                        }
                    }
                }
                input{
                    width: calc(8/9*100%);
                    @include max(1200px) {
                        font-size: 1.4rem;
                    }
                }
                .btn-coppy{
                    flex: 1;
                    cursor: pointer;
                    i{
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
}
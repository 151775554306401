@keyframes ring-ring {
    0%{
        transform: rotate(0deg) scale(1) skew(1deg);
    }
    10%{
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20%{
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    30%{
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40%{
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    50%{
        transform: rotate(0deg) scale(1) skew(1deg);
    }
    100%{
        transform: rotate(0deg) scale(1) skew(1deg);
    }
}



@keyframes zoomTab {
    0%{
        display: none;
        opacity: 0;
        transform: scale(.95);
    }
    100%{
        display: block;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;
        transform: translate(100%, -50%);
    }
    // 60% {
    //     opacity: 1;
    //     transform: translate(25%, -50%);
    // }
    80% { transform: translate(45%, -50%); }
    100% { right: 50%;
        transform: translate(50%, -50%); }
}


@keyframes autoScroll {
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-100%);
    }
}

@keyframes runDown {
    0%{
        opacity: 0;
        transform: translate(0, -2rem) scale(1);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: translate(0, 2rem) scale(0.8);
    }
}

@keyframes arrowRight {
    0%{
        opacity: 0;
        transform: translate(-1rem, 0);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: translate(1rem, 0);
    }
}


@keyframes scaleOpa {
    0%{
        opacity: 0;
        transform: scale(1);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: scale(0.8);
    }
}

@keyframes lightNeon {
    // 0%, 100% {
    //     opacity: 1;
    //     }
    // 25%, 75% {
    //     opacity: 0;
    // }
    0%    { opacity: 1; }
    3%    { opacity: 0.4; }
    6%    { opacity: 1; }
    7%    { opacity: 0.4; }
    8%    { opacity: 1; }
    9%    { opacity: 0.4; }
    10%   { opacity: 1; }       
    89%   { opacity: 1; }
    90%   { opacity: 0.4; }
    100%  { opacity: 0.4; }
}


@keyframes dashRun {
    100% {
        background-position: 100% 0, 0 100%, 0 0, 100% 100% ;
    }
}

@keyframes aniSlideX {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% + var(--width)));
    }
}

@keyframes upDown {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(2%);
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes scaleAni {
    0%{
        opacity: 0.2;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0.2;
    }
}

@keyframes scale {
    0%{
        transform: scale(0.8);
        opacity: 0;
    }
    50%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(1.2);
        opacity: 0;
    }
}
@keyframes fadeUp {
    0%{
        transform: translate(0,20%);
        opacity: 0;
        visibility: hidden;
    }
    100%{
        transform: translate(0,0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes rotate {
    0%{
        transform: rotate(0) scale(1.1);
    }
    100%{
        transform: rotate(180deg) scale(1);
    }
}

@keyframes rotateUpDown {
    0% {
        transform: translateY(0) scale(1);
        opacity: 0.2;
    }
    50% {
        transform: translateY(-15%) scale(1.1);
        opacity: 1;
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 0.2;
    }
}

@keyframes opa {
    0%{
        opacity: 0.2;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0.2;
    }
}

@keyframes shooting {
    0% {
        transform: translate(-5px, -3px) rotate(-4deg);
    }
    100% {
        transform: translate(5px, 0) rotate(0deg);
    }
}

@keyframes shooting2 {
    0% {
        transform: translate(10px, -3px) rotate(5deg);
    }
    100% {
        transform: translate(-10px, 0) rotate(0deg);
    }
}

@keyframes bulletMove {
    0% { transform: translateX(0); }
    50% { transform: translateX(200px) ; }
    100% { transform: translateX(400px); }
}

@keyframes scaleAni {
    0%{
        transform: scale(0.5);
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes showUp {
    to{
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes shine {
    100%{
        left: 180%;
    }
}

@keyframes introLightLeft {
    0%{
        transform: skew(0deg);
    }
    30%{
        transform: skew(-29deg);
    }
    50%{
        transform: skewX(20deg);
    }
    100%{
        transform: skew(0deg);
    }
}

@keyframes introLightRight {
    0%{
        transform: skew(0deg);
    }
    30%{
        transform: skew(29deg);
    }
    50%{
        transform: skewX(-20deg);
    }
    100%{
        transform: skew(0deg);
    }
}


@keyframes sparkle {
    0% { transform: scale(0);
    }
    25% { opacity: 1; 
    }
    100% { opacity: 0; 
    }
}

@keyframes showUp {
    0%{
        opacity: 0;
        visibility: hidden;
        transform: translateY(7rem);
    }
    100%{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

@keyframes shakeJump {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(0, -10rem) rotate(0deg); }
    100% { transform: translate(0, 0) rotate(0deg); }
}


// color
.cl-pri{
    color: $color-pri;
}

.cl-sec{
    color: $color-second;
}

.cl-third{
    color: $color-third;
}

.cl-white{
    color: $white;
}

.cl-black{
    color: $black;
}

.cl-text{
    color: $color-text;
}

.cl-green{
    color: #67B642;
}

.cl-cus{
    color: #1F1F1F;
    font-style: italic;
}

// background
.bg-pri{
    background-color: $color-pri;
}

.bg-sec{
    background-color: $color-second;
}

.bg-third{
    background-color: $color-third;
}

.bg-white{
    background-color: $white;
}

.bg-black{
    background-color: $black;
}
.hd{
    position: sticky;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: var(--size-hd);
    transition: all .4s ease-out;
    &.hide-hd {
        transform: translateY(-12.2rem);
        @include max(1200px) {
            transform: translateY(-9.6rem);
        }
    }
}
.hd-top{
    background-color: $color-pri;
    height: var(--hd-top);
    .container{
        max-width: 100%;
        padding: 0 8rem;
        @include max(1200px) {
            padding: 0 1.5rem;
        }
    }
    .hd-top-wr{
        height: 100%;
        @include flexSpc;
        align-items: center;
        margin: 0 -0.8rem;
    }
    .hd-top-sc{
        width: calc(3/12*100%);
        @include max(1200px) {
            width: calc(2/12*100%);
        }
        @include max(700px) {
            display: none;
        }
    }
    .social-list{
        padding: 0 0.8rem;
        .social-link{
            display: block;
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 1.2rem;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .hd-top-noti{
        width: calc(6/12*100%);
        height: 100%;
        padding: 0 0.8rem;
        @include max(1200px) {
            width: calc(8/12*100%);
        }
        @include max(700px) {
            width: calc(10/12*100%);
        }
        @include max(360px) {
            width: calc(9/12*100%);
        }
        .noti{
            height: 100%;
            font-size: 1.2rem;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            .ic{
                @include icon(block, 1.6, 1.6, );
                margin-right: 1.2rem;
                img{
                    @include imgFull
                }
            }
            .txt{
                width: calc(8/12*100%);
                overflow: hidden;
                @include max(1200px) {
                    width: calc(8/12*100%);
                }
                @include max(575px) {
                    width: calc(11/12*100%);
                }
            }
            .txt-slider{
                .swiper-slide{
                    width: max-content;
                    max-width: max-content;
                    padding-right: 2.4rem;
                    .hd-top-txt{
                        width: max-content;
                        p{
                            font-size: 1.2rem;
                        }
                    }
                }
                .swiper-wrapper{
                    transition-timing-function: linear !important;
                }
            }
            .link{
                display: block;
                text-decoration: underline;
                text-underline-position: under;
                margin-left: 1.2rem;
                @include max(575px) {
                    display: none;
                }
            }
        }
    }
    .hd-top-lg{
        width: calc(3/12*100%);
        @include flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 1.2rem;
        font-weight: 600;
        color: $red-opa;
        padding: 0 0.8rem;
        @include max(1200px) {
            width: calc(2/12*100%);
        }
        @include max(360px) {
            width: calc(3/12*100%);
        }
        .lg-item{
            position: relative;
            display: block;
            &.active{
                color: $white;
            }
            &:first-child{
                padding-right: 1.6rem;
                &::after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0.8rem;
                    transform: translate(-50%, -50%);
                    width: 0.1rem;
                    height: 1.2rem;
                    background-color: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }
}
.hd-bot{
    background-color: $black-sec;
    height: var(--hd-bot);
    .hd-bot-wr{
        width: var(--w-hd);
        height: 100%;
        margin-left: auto;
        @include flexSpc();
        align-items: center;
        padding-right: 7rem;
        @include max(1300px) {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
        }
    }
    .custom-logo-link{
        display: block;
        height: var(--w-logo);
        img{
            @include imgFull;
        }
    }
}

.hd-action{
    display: flex;
    align-items: center;
    margin: 0 -0.8rem;
    .hd-reg{
        margin-right: 2.4rem;
        padding: 0 0.8rem;
        @include max(500px) {
            margin-right: 0.5rem;
        }
        .login{
            color: $white;
            &.txt{
                transition: .4s;
                &:hover{
                    color: $color-second;
                }
                @include max(575px) {
                    display: none;
                }
            }
            &.icon{
                @include min(575px) {
                    display: none;
                }
                i{
                    font-size: 2rem;
                }
            }
            &.user {
                display: flex;
                align-items: center;
                .img{
                    @include icon(flex, 2.4, 2.4);
                    margin-right: 0.8rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .txt{
                    color: $white;
                    font-size: 1.4rem;
                    font-weight: 600;
                    @include max(360px) {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
    .hd-cart{
        padding: 0 0.8rem;
        .box-cart{
            position: relative;
        }
        .img{
            width: 2.4rem;
            height: 2.4rem;
            img{
                @include imgFull;
            }
        }
        .box-num{
            @include flexCenter;
            width: 2rem;
            height: 2rem;
            position: absolute;
            top: -1.2rem;
            right: -1rem;
            background-color: $color-pri;
            border-radius: 50%;
            .num{
                font-size: 1rem;
                font-weight: 600;
                color: $white;
            }
        }
    }
    .hd-bar{
        margin-left: 2.4rem;
        padding: 0 0.8rem;
        @include max(500px) {
            margin-left: 0.5rem;
        }
    }
}

.hd-nav{
    height: 100%;
    @include max(1200px) {
        display: none;
    }
    >.menu-list{
        height: 100%;
        >.menu-item{
            position: relative;
            transition: .4s;
            height: 100%;
            display: flex;
            align-items: center;
            >.menu-link{
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                white-space: nowrap;

                font-size: 1.8rem;
                font-weight: 700;
                padding: 1.2rem 1.6rem;
                color: $white;

                transition: .4s;
                .ic-down{
                    display: flex;
                    margin-left: 0.8rem;
                    transition: .4s;
                    i{
                        font-size: 1.6rem;
                    }
                }
            }
            &.current-menu-item{
                .menu-link{
                    color: $yel;
                }
            }
            &:hover{
                >.menu-link{
                    color: $yel;
                    .ic-down{
                        transform: rotate(180deg);
                    }
                }
                >.menu-list{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.dropdown{
    .menu-list{
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $color-pri;
        width: 15rem;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
        .menu-item{
            .menu-link{
                display: flex;
                padding: 1.2rem 1.6rem;
                color: $white;
                transition: all .3s ease-in-out;
                &:hover{
                    background-color: $color-second;
                }
            }
        }
    }
}

.menu-mb{
    width: 30rem;
    position: fixed;
    top: var(--size-hd);
    left: 0;
    bottom: 0;
    background-color: $black-sec;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
    transition: all .4s ease-out;
    @include max(360px) {
        width: 90%;
    }
    .menu-mb-inner{
        padding-bottom: 2rem;
        @include flexCol;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }
    .nav-mb{
        margin-bottom: 1.6rem;
        flex: 1;
    }
    .menu-list{
        .menu-item.current-menu-item{
            .menu-link{
                color: $yel;
            }
        }
        .menu-link{
            @include flexSpc;
            align-items: center;

            padding: 1rem;

            font-size: 1.4rem;
            color: $white;
        }
        .dropdown{
            >.menu-list{
                display: none;
                position: unset;
                background-color: transparent;
                width: 100%;
                opacity: 1;
                visibility: visible;
                margin-left: 1rem;
                border-left: 0.1rem solid $yel-sec;
                transition: none;
            }
            .menu-link{
                color: $white;
                .ic-down{
                    display: flex;
                    padding-left: 1rem;
                    i{
                        transition: all 0.3s ease-in-out;
                        font-size: 1.4rem;
                    }
                    &.open{
                        i{
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
    .social-ft{
        margin-bottom: 1.6rem;
    }
    .social-list {
        padding: 0 1rem;
        flex: 1;
        .social-link{
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .box-mail{
        padding: 0 1rem;
        p{
            color: $white;
            font-size: 1.4rem;
            margin-bottom: 1.6rem;
        }
        .input{
            padding: 1rem;
        }
        input{
            font-size: 1.4rem;
            &::placeholder{
                font-size: 1.4rem;
            }
        }
    }
    &.active{
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }
}
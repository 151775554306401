.breadcrumbs-list{
    justify-content: center;
    flex-wrap: wrap;
    .breadcrumbs-item{
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 1.2rem;
        &:not(:last-child){
            &::after{
                content: '';
                width: 2.4rem;
                height: 2.4rem;
                background-image: url(/template/assets/images/arr-r.svg);
                background-repeat: no-repeat;
                margin-left: 1.2rem;
            }
        }
        &:last-child{
            margin-right: 0;
            .breadcrumbs-link{
                color: $white;
                font-weight: 600;
            }
        }
        .breadcrumbs-link{
            color: $grey-pri;
            font-size: 1.8rem;
            @include lc(2);
        }
    }
}